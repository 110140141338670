import { useContext } from 'react';
import { logger } from '@noah-labs/shared-logger/browser';
import type { CxOry } from './OryContext';
import { OryContext } from './OryContext';

export function useOry(): CxOry {
  const context = useContext(OryContext);
  if (context === undefined) {
    return logger.fatal('useOry must be used within an OryProvider');
  }
  return context;
}
