import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgArrowUpRightCircleThick } from '../icons/ArrowUpRightCircleThick';

export function DepositIcon({ sx, ...props }: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon
      {...props}
      component={SvgArrowUpRightCircleThick}
      data-qa="DepositIcon"
      sx={{ transform: 'rotate(90deg)', ...sx }}
    />
  );
}
