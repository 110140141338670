import creditcard from '@noah-labs/fe-shared-ui-assets/svg/icons/credit-card.svg';
import mastercardlight from '@noah-labs/fe-shared-ui-assets/svg/images/mastercard-light.svg';
import visalight from '@noah-labs/fe-shared-ui-assets/svg/images/visa-light.svg';
import { FiatPaymentCardScheme } from '@noah-labs/shared-schema-gql';
import { getFiatPaymentCardScheme } from './getFiatPaymentCardScheme';

export function getCardSchemeIcon(scheme: string | undefined): TpReactSvg {
  const fpCardScheme = getFiatPaymentCardScheme(scheme);

  switch (fpCardScheme) {
    case FiatPaymentCardScheme.Visa:
      return visalight;
    case FiatPaymentCardScheme.Mastercard:
      return mastercardlight;
    default:
      return creditcard;
  }
}
