import { OnAccountUpdateDocument } from './subscriptions.generated';

export type TpSubId = 'onAccountUpdate';

export type TpSubscribeQuery = {
  id: TpSubId;
  query: string;
  variables?: Record<string, unknown>;
};

export const subscriptions: TpSubscribeQuery[] = [
  {
    id: 'onAccountUpdate',
    query: OnAccountUpdateDocument,
  },
];
