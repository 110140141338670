import type { PpCheckbox } from '../forms/Checkbox';
import { Checkbox } from '../forms/Checkbox';
import type { PpDefaultListItemText } from '../lists/DefaultListItemText';

type PpConsentOption = Omit<PpCheckbox, 'label'> & {
  label: PpDefaultListItemText;
};
export function ConsentOption({ label, ...rest }: PpConsentOption): React.ReactElement {
  return (
    <Checkbox
      label={label.primary}
      primaryTypographyProps={{ sx: { mb: 0.25 } }}
      secondaryLabel={label.secondary}
      {...rest}
    />
  );
}
