import { useCallback } from 'react';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { createStore, StateMachineProvider, useStateMachine } from 'little-state-machine';
import type { GlobalState } from 'little-state-machine';

export type StNavigation = {
  exitTo: string;
  search: string | undefined;
};

type TpNavigation = {
  exitTo: string;
  updateExitTo: (newExitTo: string) => void;
};
export function useNavigation(): TpNavigation {
  const { actions, state } = useStateMachine({
    updateExitTo: (currentState: GlobalState, payload: Pick<StNavigation, 'exitTo'>) => ({
      ...currentState,
      exitTo: payload.exitTo,
    }),
  });

  const updateExitTo = useCallback(
    (exitTo: string): void => {
      actions.updateExitTo({ exitTo });
    },
    [actions],
  );

  const { exitTo } = state;
  return { exitTo, updateExitTo };
}

type PpNavigationProvider = PpWC & {
  initialExitTo: string;
  initialSearch: string;
};
export function NavigationProvider({
  children,
  initialExitTo,
  initialSearch,
}: PpNavigationProvider): React.ReactElement {
  const initialState = { exitTo: initialExitTo, search: initialSearch };
  createStore(initialState, { name: 'rootNavigation' });
  return <StateMachineProvider>{children}</StateMachineProvider>;
}
