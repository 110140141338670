import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChevronDownIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronDownIcon';
import { DefaultAvatar } from '../images/DefaultAvatar';
import { HeaderIconButton } from './HeaderIconButton';

export type PpAvatarIconButton = {
  avatarContent?: string;
  href: string;
  showLnAddress?: boolean;
  sx: SxProps<Theme>;
  userLnAddress?: string;
  usernameDisplay?: string | null | undefined;
};

export function AvatarIconButton({
  avatarContent,
  href,
  showLnAddress,
  sx,
  userLnAddress,
  usernameDisplay,
}: PpAvatarIconButton): React.ReactElement {
  const theme = useTheme();

  const styles = {
    detailsStack: css`
      align-items: center;
      margin-left: ${theme.spacing(1)};
      color: ${theme.palette.text.light};
    `,
    withLnAddress: css`
      border-radius: ${theme.borderRadius.sm};
      padding-right: ${theme.spacing(1)};
    `,
  };

  return (
    <HeaderIconButton
      aria-label="Open menu"
      css={showLnAddress ? styles.withLnAddress : undefined}
      data-qa="settings-button"
      href={href}
      sx={sx}
    >
      <DefaultAvatar
        alt={usernameDisplay || 'User avatar'}
        size={3.5}
        userInitials={avatarContent}
      />

      {showLnAddress && usernameDisplay && (
        <Stack css={styles.detailsStack} direction="row" spacing={1}>
          <Typography variant="paragraphBodySBold">{userLnAddress}</Typography>
          <ChevronDownIcon />
        </Stack>
      )}
    </HeaderIconButton>
  );
}
