import type { SVGProps } from 'react';

export function SvgPending(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8 12.001C22.8 17.965 17.965 22.8 12 22.8S1.2 17.965 1.2 12.001s4.835-10.8 10.8-10.8 10.8 4.836 10.8 10.8Z"
        fill="currentColor"
        stroke="#fff"
        strokeWidth={2.4}
      />
      <path
        clipRule="evenodd"
        d="m16 15.335-4-2.958V6.668"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="m16 15.335-4-2.958V6.668"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.4}
      />
    </svg>
  );
}
