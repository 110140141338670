import type { LinkProps } from '@mui/material';
import { InfoIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/InfoIcon';
import { TextWithIcon } from '../typography/TextWithIcon';
import { InlineLinkOrButton } from './InlineLinkOrButton';

export function InfoTooltip({ children, ...rest }: LinkProps): React.ReactElement {
  return (
    <InlineLinkOrButton color="inherit" variant="inherit" {...rest}>
      <TextWithIcon>
        <span>{children}</span>
        <InfoIcon fontSize="inherit" />
      </TextWithIcon>
    </InlineLinkOrButton>
  );
}
