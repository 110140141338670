import { forwardRef } from 'react';
import type { StackProps } from '@mui/material';
import { Stack } from '@mui/material';

export const HeaderIconButtonGroup = forwardRef<HTMLDivElement, StackProps>((props, ref) => (
  <Stack
    direction="row"
    sx={{ alignItems: 'center', flexShrink: 0, justifyContent: 'center' }}
    {...props}
    ref={ref}
  />
));

HeaderIconButtonGroup.displayName = HeaderIconButtonGroup.name;
