import type { ReactElement } from 'react';
import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

export type PpAppMain = PpWC &
  Pick<BoxProps, 'sx'> & {
    paddingBottom?: number;
    withNavbar?: boolean;
  };

export function AppMain({ children, paddingBottom = 8, sx, withNavbar }: PpAppMain): ReactElement {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: [1, 0],
        paddingBottom: (theme) => (withNavbar ? theme.spacingForNavbar : paddingBottom),
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
