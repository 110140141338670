import type { SerializedStyles } from '@emotion/react';
import type { FormControlLabelProps, SwitchProps as MuiSwitchProps } from '@mui/material';
import { FormControlLabel, Switch as MuiSwitch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export type PpSwitch = Omit<MuiSwitchProps, 'id' | 'name'> &
  Pick<FormControlLabelProps, 'label' | 'labelPlacement'> & {
    dataQa?: string;
    inputCss?: SerializedStyles;
    name: string;
  };

export function Switch({
  dataQa,
  inputCss,
  label,
  labelPlacement,
  name,
  ...rest
}: PpSwitch): React.ReactElement {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }): React.ReactElement => (
        <FormControlLabel
          {...field}
          control={
            <MuiSwitch
              checked={field.value}
              id={name}
              inputProps={{
                // @ts-expect-error false positive
                'data-qa': dataQa ? `${dataQa}-switch` : 'switch',
              }}
              {...rest}
            />
          }
          css={inputCss}
          label={label}
          labelPlacement={labelPlacement}
        />
      )}
    />
  );
}
