import type { ReactElement, ReactNode } from 'react';
import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Textfit } from 'react-textfit';

export type PpBalance = {
  IconSlot?: React.ReactElement;
  PrimaryAmountSlot?: ReactNode;
  SecondaryAmountSlot?: ReactNode;
  label?: string;
};

const headingLMaxFontSizeFallback = 40;

export function Balance({
  IconSlot,
  label,
  PrimaryAmountSlot,
  SecondaryAmountSlot,
}: PpBalance): ReactElement {
  const theme = useTheme();

  const styles = {
    inner: css`
      position: relative;
    `,
    outer: css`
      text-align: center;
      width: 100%;
    `,
  };

  const headingLFontSizeFromTheme = theme.typography.headingL?.fontSize?.toString().split('rem')[0];
  const headingLFontSizeInPixel = headingLFontSizeFromTheme
    ? parseFloat(headingLFontSizeFromTheme) * theme.typography.htmlFontSize
    : headingLMaxFontSizeFallback;

  return (
    <Stack css={styles.outer}>
      {label && (
        <Typography gutterBottom color="text.light" variant="paragraphBodyM">
          {label}
        </Typography>
      )}
      <div css={styles.inner}>
        <Stack spacing={1}>
          {PrimaryAmountSlot && (
            <Stack justifyContent="center" sx={{ minHeight: theme.spacing(4) }}>
              <Typography
                data-qa="primary-amount"
                sx={{
                  margin: theme.spacing(0, 8),
                }}
                variant="headingL"
              >
                <Textfit max={headingLFontSizeInPixel} min={16} mode="single">
                  {PrimaryAmountSlot}
                </Textfit>
              </Typography>
            </Stack>
          )}
          {SecondaryAmountSlot && (
            <Typography color="text.light" data-qa="secondary-amount" variant="paragraphBodyS">
              {SecondaryAmountSlot}
            </Typography>
          )}
        </Stack>
        {IconSlot}
      </div>
    </Stack>
  );
}
