import { Skeleton } from '@mui/material';
import { AvatarIcon } from '../images/Avatar';
import { TextOrSkeleton } from '../typography/TextOrSkeleton';
import {
  ListItemContent,
  ListItemEndContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from './ListItem';

export type PpSkeletonListItemContent = {
  avatar?: boolean;
  endPrimary?: boolean;
  endSecondary?: boolean;
  startPrimary?: boolean;
  startSecondary?: boolean;
};
export function SkeletonListItemContent({
  avatar = true,
  endPrimary = true,
  endSecondary = true,
  startPrimary = true,
  startSecondary = true,
}: PpSkeletonListItemContent): React.ReactElement {
  return (
    <ListItemContent>
      {avatar && (
        <ListItemIcon>
          {' '}
          <AvatarIcon>
            <Skeleton variant="circular" />
          </AvatarIcon>
        </ListItemIcon>
      )}
      <ListItemStartContent>
        {startPrimary && (
          <ListItemPrimaryText>
            <TextOrSkeleton />
          </ListItemPrimaryText>
        )}
        {startSecondary && (
          <ListItemSecondaryText>
            <TextOrSkeleton />
          </ListItemSecondaryText>
        )}
      </ListItemStartContent>
      <ListItemEndContent>
        {endPrimary && (
          <ListItemPrimaryText>
            <TextOrSkeleton />
          </ListItemPrimaryText>
        )}
        {endSecondary && (
          <ListItemSecondaryText>
            <TextOrSkeleton />
          </ListItemSecondaryText>
        )}
      </ListItemEndContent>
    </ListItemContent>
  );
}
