import React from 'react';
import { Container, type ContainerProps } from '@mui/material';
import { styledMixins } from '../theme/styledMixins';

export function CenteredFullPage({ sx, ...props }: ContainerProps): React.ReactElement {
  return (
    <Container
      maxWidth="md"
      sx={{
        height: '100%',
        ...styledMixins.colCentered,
        ...sx,
      }}
      {...props}
    />
  );
}
