import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import { btcToSats, isBTC } from '@noah-labs/shared-currencies';
import type { TpAmount } from '@noah-labs/shared-currencies';
import { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';
import { TextOrSkeleton } from '../typography/TextOrSkeleton';
import { renderAmountAsParts } from './renderAmountAsParts';

export type PpCryptoAmount = {
  amount: TpAmount;
  approx?: boolean;
  currency?: TpCryptoCurrencyUI;
  currencyUnit?: CurrencyUnit;
  dataQa?: string;
  fallback?: React.ReactNode;
  fallbackCheck?: (amount: TpAmount) => boolean;
  locale?: string;
  roundDown?: boolean;
  signDisplay?: Intl.NumberFormatOptions['signDisplay'];
  strikeThrough?: boolean;
};

type PpGetAmountAsParts = {
  amount: TpAmount;
  currency?: TpCryptoCurrencyUI;
  currencyUnit?: CurrencyUnit;
  locale?: string;
  roundDown?: boolean;
  signDisplay?: Intl.NumberFormatOptions['signDisplay'];
};

function getAmountAsParts({
  amount,
  currency,
  currencyUnit,
  locale,
  roundDown,
  signDisplay,
}: PpGetAmountAsParts): Intl.NumberFormatPart[] | null | undefined {
  // Number(null) === 0, hence check this first
  if (isUndefinedOrNull(amount)) {
    return amount;
  }

  const amountAsNumber = Number(amount);
  if (Number.isNaN(amountAsNumber)) {
    return [];
  }

  const isDefaultCryptoUnit = currencyUnit === CurrencyUnit.Default;

  // Used to switch when the number will be formatted in the compact way (10M)
  const compactFormatAt = 10000000;
  const absoluteNumber = Math.abs(amountAsNumber);

  const options: Intl.NumberFormatOptions = {
    compactDisplay: 'short',
    notation: 'standard',
    // @ts-expect-error missing roundingMode in options type
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#rounding_modes
    roundingMode: roundDown ? 'trunc' : 'halfExpand',
    signDisplay,
    style: 'decimal',
  };

  if (isDefaultCryptoUnit) {
    switch (true) {
      case absoluteNumber === 0: {
        options.minimumFractionDigits = 2;
        options.maximumFractionDigits = 2;
        break;
      }
      case absoluteNumber < 1: {
        options.minimumSignificantDigits = 1;
        options.maximumSignificantDigits = 5;
        break;
      }
      case absoluteNumber >= compactFormatAt: {
        options.notation = 'compact';
        options.minimumFractionDigits = 2;
        options.maximumFractionDigits = 2;
        break;
      }
      default:
        options.minimumFractionDigits = 2;
        break;
    }
  } else if (absoluteNumber >= compactFormatAt) {
    options.notation = 'compact';
    options.minimumFractionDigits = 2;
    options.maximumFractionDigits = 2;
  }

  const formatter = new Intl.NumberFormat(locale, options);
  const amountAsParts = formatter.formatToParts(amountAsNumber);

  if (currency && currencyUnit) {
    amountAsParts.push({
      type: 'currency',
      value: isDefaultCryptoUnit ? currency.code : currencyUnit,
    });
  }
  return amountAsParts;
}

export function CryptoAmount({
  amount, // Must come in as Default unit BTC
  approx = false,
  currency,
  currencyUnit,
  dataQa = 'crypto-amount',
  fallback,
  fallbackCheck,
  locale,
  roundDown,
  signDisplay,
  strikeThrough,
}: PpCryptoAmount): React.ReactElement {
  let cryptoAmount = amount;
  const textDecoration = strikeThrough ? 'line-through' : 'none';

  let cryptoCurrencyUnit = CurrencyUnit.Default;
  if (currencyUnit === CurrencyUnit.SATS && isBTC(currency?.code)) {
    cryptoAmount = btcToSats(amount);
    cryptoCurrencyUnit = currencyUnit;
  }

  const amountAsParts = getAmountAsParts({
    amount: cryptoAmount,
    currency,
    currencyUnit: cryptoCurrencyUnit,
    locale,
    roundDown,
    signDisplay,
  });

  return (
    <span css={{ textDecoration }} data-qa={dataQa}>
      <TextOrSkeleton fallback={fallback} pretext={approx && '≈ '}>
        {fallbackCheck && fallbackCheck(amount)
          ? ''
          : amountAsParts && renderAmountAsParts({ amountAsParts, isCrypto: true })}
      </TextOrSkeleton>
    </span>
  );
}
