function isObject(error: unknown): error is Record<string, unknown> {
  if (!error || typeof error !== 'object') {
    return false;
  }
  return true;
}
function hasDescription(error: unknown): error is Record<'description', string> {
  if (!isObject(error) || typeof error.description !== 'string') {
    return false;
  }
  return true;
}
function hasName(error: unknown): error is Record<'name', string> {
  if (!isObject(error) || typeof error.name !== 'string') {
    return false;
  }
  return true;
}
function hasMessage(error: unknown): error is Record<'message', string> {
  if (!isObject(error) || typeof error.message !== 'string') {
    return false;
  }
  return true;
}

/**
 * Tries to extract error messages from various errors we get from external sources
 */
export function getErrorMessage(error: unknown): string | undefined {
  if (hasMessage(error)) {
    return error.message;
  }
  if (hasDescription(error)) {
    return error.description;
  }
  if (hasName(error)) {
    return error.name;
  }
  return undefined;
}
