import { logger } from '@noah-labs/shared-logger/browser';
import axios from 'axios';
import type { AxiosInstance, AxiosResponse } from 'axios';
import type {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';
import { useSigningJwt } from '../hooks/useSigningJwt';
import type {
  TpDecryptSecretDocumentRequest,
  TpDecryptSecretDocumentResponse,
  TpGetSecretDocumentParams,
  TpGetSecretDocumentResponse,
  TpPostSecretDocumentRequest,
  TpPostSecretDocumentSupersedeRequest,
  TpRevokeSecretDocumentRequest,
  TpUnrevokeSecretDocumentRequest,
} from '../types';

class Api {
  private axiosClient: AxiosInstance | undefined;

  configure(url: string): void {
    this.axiosClient = axios.create({
      baseURL: url,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
      },
    });
  }

  get client(): AxiosInstance {
    if (!this.axiosClient) {
      return logger.fatal('Api has not been configured');
    }
    return this.axiosClient;
  }
}

export const api = new Api();

export function usePostSecretDocument<TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<unknown, TError, TpPostSecretDocumentRequest, TContext>,
): UseMutationResult<unknown, TError, TpPostSecretDocumentRequest, TContext> {
  const { data } = useSigningJwt();

  return useMutation<unknown, TError, TpPostSecretDocumentRequest, TContext>(
    async (req: TpPostSecretDocumentRequest) => {
      if (!data) {
        return Promise.reject(new Error('No signing JWT'));
      }
      return api.client.post('/secretdocument', req, {
        headers: {
          Authorization: `Bearer ${data.signingJwt.Token}`,
        },
      });
    },

    options,
  );
}

export function usePostSecretDocumentSupersede<TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<unknown, TError, TpPostSecretDocumentRequest, TContext>,
): UseMutationResult<unknown, TError, TpPostSecretDocumentSupersedeRequest, TContext> {
  const { data } = useSigningJwt();

  return useMutation<unknown, TError, TpPostSecretDocumentSupersedeRequest, TContext>(
    async (req: TpPostSecretDocumentSupersedeRequest) => {
      if (!data) {
        return Promise.reject(new Error('No signing JWT'));
      }
      return api.client.post('/secretdocument', req, {
        headers: {
          Authorization: `Bearer ${data.signingJwt.Token}`,
        },
      });
    },

    options,
  );
}

export function useDecryptSecretDocument<TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AxiosResponse<TpDecryptSecretDocumentResponse>,
    TError,
    TpDecryptSecretDocumentRequest,
    TContext
  >,
): UseMutationResult<
  AxiosResponse<TpDecryptSecretDocumentResponse>,
  TError,
  TpDecryptSecretDocumentRequest,
  TContext
> {
  const { data } = useSigningJwt();

  return useMutation<
    AxiosResponse<TpDecryptSecretDocumentResponse>,
    TError,
    TpDecryptSecretDocumentRequest,
    TContext
  >(async (req: TpDecryptSecretDocumentRequest) => {
    if (!data) {
      return Promise.reject(new Error('No signing JWT'));
    }
    return api.client.post<TpDecryptSecretDocumentResponse>('/secretdocument/decrypt', req, {
      headers: {
        Authorization: `Bearer ${data.signingJwt.Token}`,
      },
    });
  }, options);
}

export function useGetSecretDocument<
  TData = AxiosResponse<TpGetSecretDocumentResponse>,
  TError = unknown,
>(
  params?: TpGetSecretDocumentParams,
  options?: UseQueryOptions<AxiosResponse<TpGetSecretDocumentResponse>, TError, TData>,
): UseQueryResult<TData, TError> {
  const { data } = useSigningJwt();

  return useQuery<AxiosResponse<TpGetSecretDocumentResponse>, TError, TData>(
    'GetSecretDocument',
    async () => {
      if (!data) {
        return Promise.reject(new Error('No signing JWT'));
      }
      return api.client.get<TpGetSecretDocumentResponse>('/secretdocument', {
        headers: {
          Authorization: `Bearer ${data.signingJwt.Token}`,
        },
        params,
      });
    },
    options,
  );
}

export function useRevokeSecretDocument<TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<AxiosResponse, TError, TpRevokeSecretDocumentRequest, TContext>,
): UseMutationResult<AxiosResponse, TError, TpRevokeSecretDocumentRequest, TContext> {
  const { data } = useSigningJwt();

  return useMutation<AxiosResponse, TError, TpRevokeSecretDocumentRequest, TContext>(
    async (req: TpRevokeSecretDocumentRequest) => {
      if (!data) {
        return Promise.reject(new Error('No signing JWT'));
      }
      return api.client.post<TpRevokeSecretDocumentRequest>('/secretdocument/revocation', req, {
        headers: {
          Authorization: `Bearer ${data.signingJwt.Token}`,
        },
      });
    },
    options,
  );
}

export function useUnrevokeSecretDocument<TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<AxiosResponse, TError, TpUnrevokeSecretDocumentRequest, TContext>,
): UseMutationResult<AxiosResponse, TError, TpUnrevokeSecretDocumentRequest, TContext> {
  return useMutation<AxiosResponse, TError, TpUnrevokeSecretDocumentRequest, TContext>(
    async (req: TpUnrevokeSecretDocumentRequest) =>
      api.client.post<TpUnrevokeSecretDocumentRequest>('/secretdocument/unrevoke', req),
    options,
  );
}
