import type { SVGProps } from 'react';

export function SvgCloseCircleThick(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8 12c0 5.965-4.835 10.8-10.8 10.8S1.2 17.965 1.2 12C1.2 6.036 6.035 1.201 12 1.201s10.8 4.835 10.8 10.8Z"
        fill="currentColor"
        stroke="#fff"
        strokeWidth={2.4}
      />
      <path
        clipRule="evenodd"
        d="M9.2 7.237a1.2 1.2 0 1 0-1.697 1.697l3.067 3.067-3.065 3.065a1.2 1.2 0 0 0 1.697 1.697l3.065-3.065 3.065 3.065a1.2 1.2 0 1 0 1.697-1.697l-3.065-3.065 3.066-3.067a1.2 1.2 0 1 0-1.697-1.697l-3.066 3.067z"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  );
}
