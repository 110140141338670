import { css } from '@emotion/react';
import { Avatar } from '@mui/material';
import type { AvatarProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ContactIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ContactIcon';

type PpDefaultAvatar = {
  size?: number;
  userInitials?: string;
};

export function DefaultAvatar({
  size = 3, // 24px
  userInitials,
  ...rest
}: PpDefaultAvatar & AvatarProps): React.ReactElement {
  const theme = useTheme();
  const sz = theme.spacing(size);

  const styles = {
    avatar: css`
      height: ${sz};
      width: ${sz};
      background-color: initial;
      color: ${theme.palette.secondary.dark};

      .MuiSvgIcon-root {
        height: 100%;
        width: 100%;
      }
    `,
    initialsContainer: css`
      background-color: ${theme.palette.brand.light};
      color: ${theme.palette.warning.light};
    `,
    initialsText: css`
      font-size: ${theme.spacing(size / 1.75)};
      font-weight: ${theme.typography.fontWeightMedium};
      text-transform: uppercase;
    `,
  };

  return (
    <Avatar css={[styles.avatar, userInitials ? styles.initialsContainer : undefined]} {...rest}>
      {userInitials ? <span css={styles.initialsText}>{userInitials}</span> : <ContactIcon />}
    </Avatar>
  );
}
