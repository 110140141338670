/**
 * Assets
 */
export const assetsHost = 'assets.noah.com';
const assetsBase = `https://${assetsHost}`;
export const assetPaths = {
  fonts: `${assetsBase}/fonts`,
  host: assetsHost,
  images: `${assetsBase}/images`,
} as const;

/**
 * Auth
 */
export const authPaths = {
  base: { path: '/auth' },
  error: { path: '/auth/error' },
  recovery: {
    code: { path: '/auth/recovery/code', title: 'Enter recovery code' },
    email: { path: '/auth/recovery/email', title: 'Forgotten your password?' },
    suspicious: { path: '/auth/recovery/suspicious', title: 'Please reset your password' },
  },
  signedOut: { path: '/auth/signedout', title: 'Signed out' },
  signIn: { path: '/auth/signin', title: 'Login' },
  signOut: { path: '/auth/signout', title: 'Signing off' },
  signUp: { path: '/auth/signup', title: 'Sign up' },
  verify: { path: '/auth/verify', title: 'Verify your email' },
} as const;
