import { useDesktop } from '../hooks/useDesktop';
import type { PpInputFieldAtom } from './atoms/InputFieldAtom';
import { InputFieldAtom } from './atoms/InputFieldAtom';

export type PpMnemonicInput = PpInputFieldAtom & {
  words: number;
};

export function MnemonicInput({ words, ...rest }: PpMnemonicInput): React.ReactElement {
  const isDesktop = useDesktop();
  const wordsPerRow = isDesktop ? 8 : 4;

  return <InputFieldAtom multiline rows={words / wordsPerRow} {...rest} />;
}
