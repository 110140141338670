import type { ReactElement } from 'react';
import { useSearchParams } from '@noah-labs/fe-shared-ui-components';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { assetPaths } from '@noah-labs/shared-constants';
import { Helmet } from 'react-helmet';
import { webConfigBrowser } from '../../webConfigBrowser';

const title = 'NOAH: The Money App of the Future';
const description =
  'Login, access your account, and instantly send, receive, buy, and sell bitcoin!';

export function Seo(): ReactElement {
  const ogImagePath = `${assetPaths.images}/app_og_image.jpg`;
  const { pathname } = useRouter();
  const searchParams = useSearchParams();
  const isReferral = searchParams?.has(webConfigBrowser.settings.referralCodeParam);

  const referralDescription =
    'NOAH enables you to instantly send, receive, buy, and sell bitcoin. Fee-free with Lightning. All in one place. Use my referral link to register in the NOAH App, so we can both receive exciting rewards.';
  const referralTwitterDescription =
    '@NOAH_HQ enables you to instantly send, receive, buy, and sell bitcoin. Fee-free with Lightning. All in one place. Use my referral link to register in the NOAH App, so we can both receive exciting rewards.';

  const url = `app.noah.com${pathname}`;

  return (
    <Helmet title={title}>
      <html lang="en" />
      <meta
        // needs user-scalable=no to prevent zooming on input fields in iframes
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no"
        name="viewport"
      />
      <meta content={ogImagePath} name="image" />
      <meta content={isReferral ? referralDescription : description} name="description" />

      {/* twitter */}
      <meta content={ogImagePath} name="twitter:image" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={title} name="twitter:title" />
      <meta
        content={isReferral ? referralTwitterDescription : description}
        name="twitter:description"
      />

      {/* og */}
      <meta content={url} property="og:url" />
      <meta content={ogImagePath} property="og:image" />
      <meta content={isReferral ? referralDescription : description} property="og:description" />
      <meta content={title} property="og:title" />

      {/* apple: 
      https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariHTMLRef/Articles/MetaTags.html
      */}
      <meta content="yes" name="apple-mobile-web-app-capable" />
      <meta content="black" name="apple-mobile-web-app-status-bar-style" />
    </Helmet>
  );
}
