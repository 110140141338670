import type { SVGProps } from 'react';

export function SvgSquareWarning(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
    >
      <rect fill="currentColor" height={23.049} rx={5.571} width={23.049} x={0.409} y={0.547} />
      <path
        d="M10.4 5.3c.7-1.3 2.6-1.3 3.3 0l5.8 10.5c.7 1.2-.2 2.7-1.6 2.7H6.2c-1.4 0-2.3-1.5-1.6-2.7z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={2.375}
      />
      <path
        d="M12.1 6.5c.7 0 1.2.5 1.2 1.1V12c0 .6-.5 1.1-1.2 1.1s-1.2-.5-1.2-1.1V7.7c0-.7.5-1.2 1.2-1.2m-.8 8c.5-.5 1.2-.5 1.7 0s.5 1.2 0 1.7-1.2.5-1.7 0-.5-1.2 0-1.7"
        fill="currentColor"
      />
    </svg>
  );
}
