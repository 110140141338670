import { css } from '@emotion/react';
import { Box } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type PpProgressBar = {
  backgroundColor?: string;
  height?: number;
  progress: number;
  sx?: SxProps<Theme>;
  variant?: 'default' | 'gradient';
};

export function ProgressBar({
  backgroundColor,
  height = 5,
  progress,
  sx,
  variant = 'default',
}: PpProgressBar): React.ReactElement {
  const theme = useTheme();
  // so we never display a tiny bar
  const minDisplayProgress = progress > 0 ? Math.max(0.1, progress) : 0;
  // so the bar can never extend more than it should
  const displayProgress = Math.min(1, minDisplayProgress);

  const progressBarBgColor = backgroundColor || theme.palette.brand.light;

  const variants = {
    default: css`
      background: ${theme.palette.divider};

      &:after {
        background: ${progressBarBgColor};
      }
    `,
    gradient: css`
      background: none;
      padding: 1px 0;

      &:after {
        background: ${`linear-gradient(270deg, ${theme.palette.brand.light} 0%, rgba(255, 102, 0, 0) 100%)`};
      }
    `,
  };

  const styles = {
    progressBar: css`
      flex-grow: 1;
      display: block;
      width: 100%;
      height: ${height}px;
      border-radius: 1em;
      padding: 1px 2px;

      &::after {
        content: '';
        display: block;
        min-width: ${height - 2}px;
        max-width: 100%;
        width: ${displayProgress * 100}%;
        height: 100%;
        border-radius: 1em;
        will-change: width;
        transition: width ${theme.transitions.easing.easeInOut}
          ${theme.transitions.duration.standard}ms;
      }
    `,
  };

  return <Box css={[styles.progressBar, variants[variant]]} sx={sx} />;
}
