import type { ReactText } from 'react';
import type { LinkProps } from '@mui/material';
import { getConfig } from '../../config';
import { InlineLinkOrButton } from './InlineLinkOrButton';

type PpGetInTouchLink = {
  text?: ReactText;
} & LinkProps;

export function GetInTouchLink({ text, ...rest }: PpGetInTouchLink): React.ReactElement {
  const {
    intercom: { customLauncherSelector },
  } = getConfig();

  return (
    <InlineLinkOrButton
      aria-label="help"
      className={customLauncherSelector}
      // set button explicitly - doesn't have an onClick prop but does have an onClick handler
      component="button"
      data-qa={customLauncherSelector}
      id={customLauncherSelector}
      {...rest}
    >
      {text ?? 'get in touch'}
    </InlineLinkOrButton>
  );
}
