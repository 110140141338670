import type { StandardCSSProperties } from '@mui/system';

const utilsStyledMixins = {
  bgImageCoverNoRepeat: (url: string): StandardCSSProperties => ({
    backgroundImage: `url(${url})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }),
};

const colCentered: StandardCSSProperties = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};
const rowCentered: StandardCSSProperties = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
};
const stretchAll: StandardCSSProperties = {
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
};
const fillParent: StandardCSSProperties = {
  height: '100%',
  width: '100%',
};

const commonStyledMixins = {
  colCentered,
  fillParent,
  rowCentered,
  stretchAll,
};

export const styledMixins = { ...commonStyledMixins, ...utilsStyledMixins };

export type TpStyledMixins = typeof styledMixins;
