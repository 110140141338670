import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

// Migration from v5 - https://mui.com/material-ui/migration/upgrade-to-v6/#breaking-changes-affecting-types
const StyledBulletedList = styled(Box)(({ theme }) => ({
  '& li': {
    paddingLeft: theme.spacing(0.5),
  },
  padding: theme.spacing(0, 0, 0, 3),
})) as typeof Box;

export function BulletedList({ children }: PpWC): React.ReactElement {
  return <StyledBulletedList component="ul">{children}</StyledBulletedList>;
}
