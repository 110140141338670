import { Typography } from '@mui/material';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

export function FooterContentText({ children }: PpWC): React.ReactElement {
  return (
    <Typography color="text.light" variant="paragraphBodyS">
      {children}
    </Typography>
  );
}
