import type { TabsProps } from '@mui/material';
import { Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ButtonGroup } from '../buttons/ButtonGroup';

export type PpTabsButton = TabsProps;

const TabsStyled = styled(Tabs)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.ghost.main,
  borderRadius: theme.borderRadius.cylinder,
  minHeight: 'auto',
  padding: theme.spacing(0.5),
  width: '100%',
}));

export function TabsButton({ children, value, ...props }: PpTabsButton): React.ReactElement {
  return (
    <TabsStyled value={value} variant="fullWidth" {...props}>
      <ButtonGroup direction="row">{children}</ButtonGroup>
    </TabsStyled>
  );
}
