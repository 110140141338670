import type { Feature, FeatureFlag } from '@noah-labs/shared-schema-gql';
import type BigNumber from 'bignumber.js';

/**
 * Supplement our Api FeatureFlags with some specific FE types
 */
export enum TpFeExtraFeature {
  // Controls access to the Buy routes
  BuyRoutes = 'BuyRoutes',
  // Controls access to the Deposit routes
  DepositRoutes = 'DepositRoutes',
  // Controls access to the Sell routes
  SellRoutes = 'SellRoutes',
}

export type TpFeFeature = Feature | TpFeExtraFeature;
export type TpFeFeatureFlag = Omit<FeatureFlag, 'Feature'> & {
  Feature: TpFeFeature;
};

type TpAllowanceReason = 'Balance' | 'PolicyLimit';
/**
 * Outlines the amount and reason for a withdrawal allowance.
 * Takes into account policy limits and balance
 */
export type TpAllowance = {
  amount: BigNumber;
  reason: TpAllowanceReason;
};

export type TpUserWithdrawalAllowance = {
  /**
   * The maximum amount of crypto that can be withdrawn. Includes balance and limits
   */
  accountAllowanceCrypto: TpAllowance | undefined;
  /**
   * The maximum amount of transactions available for day
   */
  accountDayCountLimitCrypto?: BigNumber | undefined;
  /**
   * The crypto balance of the user
   */
  balanceUserCrypto: BigNumber | undefined;
  /**
   * The maximum amount of crypto that can be withdrawn via LN. Includes balance and limits
   */
  lnAllowanceCrypto: TpAllowance | undefined;
  /**
   * The minimum amount of crypto that can be withdrawn.
   */
  withdrawMinimumSingleCrypto: BigNumber | undefined;
  /**
   * The amount of remaining transactions
   */
  withdrawalRemainingTxs: BigNumber | undefined;
};
