export function getPropertyByPath(obj: unknown, path: string[]): unknown {
  if (path.length === 0) {
    return obj;
  }
  const [property, ...rest] = path;
  if (typeof obj === 'object' && obj !== null && Object.hasOwn(obj, property)) {
    const objectWithProperty = obj as { [key: string]: unknown };
    return getPropertyByPath(objectWithProperty[property], rest);
  }
  return undefined;
}
