import { Fragment } from 'react';
import { Container, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { assetPaths } from '@noah-labs/shared-constants';
import { styledMixins } from '../theme/styledMixins';

const StackWithBackground = styled(Stack)(({ theme }) => ({
  inset: 0,
  position: 'fixed',
  [theme.breakpoints.up('md')]: {
    // adding here so we can add a opacity to the image without affecting the whole content
    '::before': {
      ...styledMixins.bgImageCoverNoRepeat(
        `${assetPaths.images}/bg-images/dashboard-onboarding.png`,
      ),
      content: '""',
      height: '100%',
      opacity: 0.7,
      position: 'absolute',
      width: '100%',
    },
  },
}));
export function OnboardingWrapper({ children }: PpWC): React.ReactElement {
  return (
    <Fragment>
      <StackWithBackground />
      <Container component="section" maxWidth="sm" sx={{ my: (theme) => theme.spacing(8) }}>
        <Stack position="relative" spacing={4}>
          {children}
        </Stack>
      </Container>
    </Fragment>
  );
}
