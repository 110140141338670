import { useTheme } from '@mui/material/styles';
import { DepositIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgCustomIcons/DepositIcon';
import { FailIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgCustomIcons/FailIcon';
import { WithdrawIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgCustomIcons/WithdrawIcon';
import { PendingIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/PendingIcon';
import { PlusCircleThickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/PlusCircleThickIcon';
import { SellIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/SellIcon';
import type { TpTransactionType } from '@noah-labs/fe-shared-ui-shared';
import { TransactionStatus } from '@noah-labs/shared-schema-gql';
import { AvatarIcon } from '../images/Avatar';

export type PpTransactionStatusBadge = {
  size?: number;
  status: TransactionStatus;
  type: TpTransactionType;
};
export function TransactionStatusBadge({
  size = 3,
  status,
  type,
}: PpTransactionStatusBadge): React.ReactElement | null {
  const theme = useTheme();

  switch (true) {
    case status === TransactionStatus.Failed:
      return (
        <AvatarIcon color={theme.palette.system.red.main} size={size}>
          <FailIcon />
        </AvatarIcon>
      );

    case status === TransactionStatus.Pending:
      return (
        <AvatarIcon color={theme.palette.grayscale[400]} size={size}>
          <PendingIcon />
        </AvatarIcon>
      );

    case type === 'Sell':
      return (
        <AvatarIcon color={theme.palette.system.blue.main} size={size}>
          <SellIcon />
        </AvatarIcon>
      );

    case type === 'Buy':
      return (
        <AvatarIcon color={theme.palette.system.green.main} size={size}>
          <PlusCircleThickIcon />
        </AvatarIcon>
      );

    case type === 'Withdraw':
      return (
        <AvatarIcon color={theme.palette.system.green.main} size={size}>
          <WithdrawIcon />
        </AvatarIcon>
      );

    case type === 'Deposit':
      return (
        <AvatarIcon color={theme.palette.system.green.main} size={size}>
          <DepositIcon />
        </AvatarIcon>
      );

    default:
      return null;
  }
}
