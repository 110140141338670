import type { Interpolation } from '@emotion/react';
import { css } from '@emotion/react';
import { IconButton } from '@mui/material';
import type { IconButtonProps, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { SwitchArrowsIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/SwitchArrowsIcon';
import { getInteractiveProps } from '../links/getInteractiveProps';
import { cssMixins } from '../theme/cssMixins';

export function CurrencySwitchButton({ onClick, ...rest }: IconButtonProps): React.ReactElement {
  const theme = useTheme();
  const styles = {
    icon: css`
      font-size: 1.25rem;
    `,
    iconButton: css`
      ${cssMixins.centerVAbsolute};
      /* adjustment to align with close / avatar icons */
      right: ${theme.spacing(-0.5)};
      color: ${theme.palette.secondary.dark};
    `,
  };

  const interactiveProps = getInteractiveProps({ onClick });

  return (
    <IconButton {...interactiveProps} css={styles.iconButton} onClick={onClick} {...rest}>
      <span css={visuallyHidden as Interpolation<Theme>}>Swap amount inputs</span>
      <SwitchArrowsIcon css={styles.icon} />
    </IconButton>
  );
}
