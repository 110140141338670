import { Fragment } from 'react';
import { Link, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { CenteredFullPage } from '../layout/CenteredFullPage';
import { SceneTitleLarge } from '../scene/Typography';

export function NotFoundPage(): React.ReactElement {
  return (
    <Fragment>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <CenteredFullPage>
        <SceneTitleLarge>404, sorry we couldn’t find this page.</SceneTitleLarge>
        <Typography>
          Please visit the application <Link href="/">homepage</Link> and try again.
        </Typography>
      </CenteredFullPage>
    </Fragment>
  );
}
