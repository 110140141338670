function isMaybeMarketingUrl(input: string): boolean {
  const marketingUrlRegex = /^https:\/\/app.noah.com\/marketing\/.+/i;
  return marketingUrlRegex.test(input);
}

function trimUrl(qrInput: string): string {
  const stripRegex = /^https:\/\/app.noah.com/i;
  return qrInput.trim().replace(stripRegex, '');
}

export function parseMarketingUrl(qrInput: string): string | null {
  const qrInputLower = qrInput.toLowerCase();

  if (isMaybeMarketingUrl(qrInputLower)) {
    const trimmedUrl = trimUrl(qrInputLower);
    return trimmedUrl;
  }

  return null;
}
