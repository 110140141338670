//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgChevronLeft } from '../icons/ChevronLeft';

export function ChevronLeftIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>,
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgChevronLeft} data-qa="ChevronLeftIcon">
      <svg />
    </SvgIcon>
  );
}
