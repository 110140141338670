import type { SxProps, Theme } from '@mui/material';
import { Stack } from '@mui/material';

type PpTextWithIcon = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export function TextWithIcon({ children, sx }: PpTextWithIcon): React.ReactElement {
  return (
    <Stack alignItems="center" direction="row" spacing={0.5} sx={sx}>
      {children}
    </Stack>
  );
}
