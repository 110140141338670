import { useCallback, useState } from 'react';
import { getCardSchemeIcon } from '@noah-labs/fe-shared-ui-shared';
import type {
  FrameElement,
  FramePaymentMethodChangedEvent,
  FrameValidationChangedEvent,
} from 'frames-react';
import type {
  FieldValues,
  Path,
  PathValue,
  UseFormSetFocus,
  UseFormSetValue,
} from 'react-hook-form';

type TpFieldsFocusInitial = {
  cardNumber: boolean;
  cvv: boolean;
  expiryDate: boolean;
};

const fieldsFocusInitial = {
  cardNumber: false,
  cvv: false,
  expiryDate: false,
};

function normalizeFramesFieldName<TFields>(
  name: FrameValidationChangedEvent['element'],
): Path<TFields> | undefined {
  switch (name) {
    case 'card-number':
      return 'cardNumber' as Path<TFields>;
    case 'expiry-date':
      return 'expiryDate' as Path<TFields>;
    case 'cvv':
      return 'cvv' as Path<TFields>;
    default:
      return undefined;
  }
}

export type TpFramesField = {
  CardSchemeIcon: TpReactSvg;
  fieldsFocus: TpFieldsFocusInitial;
  handleFrameBlur: (e: FrameElement) => void;
  handleFrameFocus: (e: FrameElement) => void;
  handleFrameValidation: (e: FrameValidationChangedEvent) => void;
  handlePaymentMethod: (e: FramePaymentMethodChangedEvent) => void;
};

export type PpUseFramesField<TFields extends FieldValues> = {
  setFocus: UseFormSetFocus<TFields>;
  setValue: UseFormSetValue<TFields>;
};

export function useFramesFields<TFields extends FieldValues>({
  setFocus,
  setValue,
}: PpUseFramesField<TFields>): TpFramesField {
  const [cardScheme, setCardScheme] = useState<string | undefined>(undefined);

  /**
   * In order to make the styling of the inputs consistent,
   * we need to manage the field focus state and send to the FrameField
   */
  const [fieldsFocus, setFieldsFocus] = useState(fieldsFocusInitial);
  const handleFrameFocus = useCallback(
    ({ element }: FrameElement) => {
      const target = normalizeFramesFieldName<TFields>(element);
      if (!target) {
        return;
      }
      setFocus(target);
      setFieldsFocus({ ...fieldsFocusInitial, [target]: true });
    },
    [setFocus],
  );
  const handleFrameBlur = useCallback(() => {
    setFieldsFocus(fieldsFocusInitial);
  }, []);
  const handleFrameValidation = useCallback(
    (validationEvent: FrameValidationChangedEvent): void => {
      const { element, isValid } = validationEvent;
      const fieldName = normalizeFramesFieldName<TFields>(element);
      if (!fieldName) {
        return;
      }

      setValue(fieldName, String(isValid) as PathValue<TFields, Path<TFields>>, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    },
    [setValue],
  );

  const handlePaymentMethod = useCallback((event: FramePaymentMethodChangedEvent): void => {
    const { isPaymentMethodAccepted, paymentMethod } = event;
    if (!isPaymentMethodAccepted) {
      setCardScheme(undefined);
      return;
    }
    setCardScheme(paymentMethod);
  }, []);

  const CardSchemeIcon = getCardSchemeIcon(cardScheme);

  return {
    CardSchemeIcon,
    fieldsFocus,
    handleFrameBlur,
    handleFrameFocus,
    handleFrameValidation,
    handlePaymentMethod,
  };
}
