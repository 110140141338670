import { useCallback, useState } from 'react';
import type { TpShareProps } from '../types';

type TpUseShare = {
  handleSharing: () => Promise<void>;
  isSupported: boolean;
  shared: boolean;
};

export function useShare(props: TpShareProps): TpUseShare {
  const [shared, setShared] = useState(false);

  const { file, onShare, text, title } = props;

  const isSupported =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    navigator.canShare &&
    navigator.canShare({ text, title, ...(file ? { files: [new File([], 'test')] } : {}) });

  const handleSharing = useCallback(async () => {
    if (!text || !isSupported) {
      return;
    }

    let shareData: ShareData = {
      text,
      title,
    };

    if (file) {
      const response = await fetch(file.url);
      const blob = await response.blob();
      const filesArray = [
        new File([blob], file.filename, {
          lastModified: new Date().getTime(),
          type: blob.type,
        }),
      ];

      shareData = {
        ...shareData,
        files: filesArray,
      };
    }

    try {
      await navigator.share(shareData);
      if (onShare) {
        void onShare();
      }
      setShared(true);
    } catch (error) {
      // Don't do anything - even a 'cancel' causes an error to propagate here
    }
  }, [file, isSupported, text, title, onShare]);

  return { handleSharing, isSupported, shared };
}
