import { isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';
import type { BIP32Interface } from 'bip32';
import {
  hpinkIterations,
  keyAlgorithm,
  ncDerivationPath,
  pinkIterations,
  pinkKdf,
  pinLength,
  svIv,
} from './constants';
import { IncorrectMnemonicError, InvalidMnemonicError, InvalidPinError } from './errors';
import { HDWallet } from './hdWallet';
import { getKey } from './keys';
import { SafeWrapper } from './types';
import type {
  SafePin,
  TpAppInput,
  TpCryptoDocuments,
  TpSecretValueRequest,
  TpSetupWallet,
  TpSignPayload,
} from './types';
import {
  derivePbkdf2Key,
  digestSha256,
  encryptWithRsa,
  generateAESKey,
  generateSalt,
  numberToFixedLenBuffer,
} from './utils';

/**
 * Encrypts NC-entropy with PIN to create the secret value (SV)
 */
export async function produceSecretValue(
  ncEntropy: ArrayBuffer,
  pink: CryptoKey,
): Promise<ArrayBuffer> {
  return globalThis.crypto.subtle.encrypt({ iv: svIv, name: 'AES-GCM' }, pink, ncEntropy);
}

/*
 * Derives H(PINk) from PINk
 * The key needs to be exported to a buffer to be re-derived into a key using the salt and iterations
 * The key is then re-exported to a buffer so it can be concatenated with the secret value
 */
async function deriveHpink(pink: CryptoKey, salt: ArrayBuffer): Promise<CryptoKey> {
  const pinkBuf = await globalThis.crypto.subtle.exportKey('raw', pink);
  return derivePbkdf2Key(pinkBuf, salt, hpinkIterations);
}

/**
 * Derives a Pbkdf2 key from PIN and a salt
 */
export function derivePink(pin: number, salt: ArrayBuffer): Promise<CryptoKey> {
  return derivePbkdf2Key(numberToFixedLenBuffer(pin, pinLength), salt, pinkIterations);
}

/**
 * Decrypt the secret value using the pink
 */
export async function decryptSecretValue(
  pinEncryptedSv: SafeWrapper<ArrayBuffer>,
  pin: SafePin,
  pinkSalt: string,
): Promise<SafeWrapper<ArrayBuffer>> {
  const pini = parseInt(pin.value.secret, 10);
  const salt = Buffer.from(pinkSalt, 'base64');
  const pink = await derivePink(pini, salt);
  const sv = await globalThis.crypto.subtle.decrypt(
    {
      iv: svIv,
      name: 'AES-GCM',
    },
    pink,
    pinEncryptedSv.value.secret,
  );

  return new SafeWrapper(sv);
}

/**
 * Derives H(PINk) from the PINk
 * Concatenates H(PINk) with SV to produce the Secret Document (SD)
 */
export async function produceSecretDocument(
  pink: CryptoKey,
  sv: ArrayBuffer,
  salt: ArrayBuffer,
): Promise<ArrayBuffer> {
  const hpink = await deriveHpink(pink, salt);
  const hpinkBuf = await globalThis.crypto.subtle.exportKey('raw', hpink);

  const sd = new Uint8Array(hpinkBuf.byteLength + sv.byteLength + 1);
  sd.set(new Uint8Array([1]), 0);
  sd.set(new Uint8Array(hpinkBuf), 1);
  sd.set(new Uint8Array(sv), hpinkBuf.byteLength + 1);

  return sd;
}

/**
 * Creates the app input used to decrypt the secret value from the enclave
 * Steps:
 * - Derives H(PINk) from the pin
 * - Generates an AES key, used to decrypt the response from the signing service
 * - Concatenates H(PINk) with the key to produce the app input
 */
export async function produceAppInput(pin: SafePin, pinkSalt: string): Promise<TpAppInput> {
  const pini = parseInt(pin.value.secret, 10);
  const salt = Buffer.from(pinkSalt, 'base64');

  const pink = await derivePink(pini, salt);
  const hpink = await deriveHpink(pink, salt);
  const hpinkBuf = await globalThis.crypto.subtle.exportKey('raw', hpink);

  const decryptionKey = await generateAESKey();
  const decryptionKeyBuf = await globalThis.crypto.subtle.exportKey('raw', decryptionKey);

  const appInput = new Uint8Array(1 + hpinkBuf.byteLength + decryptionKeyBuf.byteLength);
  appInput.set(new Uint8Array([2]), 0);
  appInput.set(new Uint8Array(hpinkBuf), 1);
  appInput.set(new Uint8Array(decryptionKeyBuf), hpinkBuf.byteLength + 1);

  return {
    appInput: new SafeWrapper(appInput),
    decryptionKey: new SafeWrapper(decryptionKey),
  };
}

/**
 * Concatenates new tag and previous tag
 * SHA256 hash the concatenated tags
 * Signs the hash with the signer from previous SD
 */
export async function produceSupersedeSignature(
  prevTag: ArrayBuffer,
  newTag: ArrayBuffer,
  signer: BIP32Interface,
): Promise<ArrayBuffer> {
  if (isUndefinedOrNull(prevTag)) {
    throw new Error('Invalid previous tag');
  }

  const tags = new Uint8Array(newTag.byteLength + prevTag.byteLength);
  tags.set(new Uint8Array(newTag), 0);
  tags.set(new Uint8Array(prevTag), newTag.byteLength);

  const hash = await digestSha256(tags);

  return signer.signSchnorr(Buffer.from(hash));
}

/**
 * Called during the secret value decrypt process
 * Contains the app input and the key used to decrypt the response from the signing service
 */
export async function getSecretValueRequest(
  pin: SafePin,
  pinkSalt: string,
  isProd: boolean,
): Promise<TpSecretValueRequest> {
  const { appInput, decryptionKey } = await produceAppInput(pin, pinkSalt);
  const [keyId, key] = await getKey({ isProd, type: 'appInput' });
  const encryptedAppInput = await encryptWithRsa(key, Buffer.from(appInput.value.secret));

  return {
    decryptionKey,
    encryptedAppInput: Buffer.from(encryptedAppInput).toString('base64'),
    keyId,
  };
}

/**
 * Decrypts the response from the enclave to recover the SV
 */
export async function decryptEnclaveResponse(
  encryptedSecretValue: SafeWrapper<string>,
  decryptionKey: SafeWrapper<CryptoKey>,
): Promise<SafeWrapper<ArrayBuffer>> {
  const sv = await globalThis.crypto.subtle.decrypt(
    {
      iv: svIv,
      name: 'AES-GCM',
      tagLength: 128,
    },
    decryptionKey.value.secret,
    Buffer.from(encryptedSecretValue.value.secret, 'base64'),
  );
  return new SafeWrapper(sv);
}

/**
 * Setup a wallet using a security PIN, generate the signing keys and recovery docunments
 * If supersede is provided, the mnemonic will be used to recreate the signing key and a supersede signature
 * Steps:
 * - Generates or recovers self and non-custody wallets from a random mnemonic or a supersede mnemonic
 * - Produces the Secret Document (SD)
 * - Derives the recovery documents to be submitted to the signing service
 */
export async function setupWallet({
  isProd,
  pin,
  supersede,
}: TpSetupWallet): Promise<TpCryptoDocuments | never> {
  const pini = parseInt(pin.value.secret, 10);
  if (pini > 10 ** pinLength) {
    throw new InvalidPinError();
  }

  const salt = await generateSalt();

  let scw;
  if (supersede) {
    scw = await HDWallet.fromMnemonic(supersede.mnemonic);
  } else {
    scw = await HDWallet.generate();
  }

  const ncEntropy = await digestSha256(scw.entropy);
  const ncw = await HDWallet.fromEntropy(new SafeWrapper(ncEntropy));
  const ncSigner = ncw.signer(ncDerivationPath);
  const publicKey = ncSigner.publicKey.slice(1); // Schnorr public keys are 32 bytes, so we remove the first byte according to BIP340

  const pink = await derivePink(pini, salt);

  const sv = await produceSecretValue(scw.entropy, pink);

  const sd = await produceSecretDocument(pink, sv, salt);

  const tag = await digestSha256(sd);
  const tagSigned = ncSigner.signSchnorr(Buffer.from(tag));

  const [keyId, key] = await getKey({ isProd, type: 'secretDocument' });
  const sdEncrypted = await encryptWithRsa(key, Buffer.from(sd));

  const result: TpCryptoDocuments = {
    keyAlgorithm,
    keyId,
    mnemonic: scw.mnemonic,
    pinkIterations,
    pinkKdf,
    pinkSalt: new SafeWrapper(salt),
    publicKey: new SafeWrapper(publicKey),
    secretDocument: new SafeWrapper(sdEncrypted),
    secretValue: new SafeWrapper(sv),
    signature: new SafeWrapper(tagSigned),
    tag: new SafeWrapper(tag),
  };

  if (supersede) {
    const supersedeSignature = await produceSupersedeSignature(supersede.prevTag, tag, ncSigner);
    return {
      ...result,
      supersedeSignature: new SafeWrapper(supersedeSignature),
    };
  }

  return result;
}

/**
 * Signs the transaction payload with the NCW
 * - hashes the payload
 * - derives the NCW from the secret value
 * - applies a Schnorr signature on the hashed payload
 * @param wallet - the ncw
 * @param payload - the transaction payload to sign
 */
export async function signPayloadWithNcw({ payload, wallet }: TpSignPayload): Promise<string> {
  const hash = await digestSha256(Buffer.from(payload, 'utf8'));

  const sig = wallet.signer(ncDerivationPath).signSchnorr(Buffer.from(hash));

  return Buffer.from(sig).toString('base64');
}

/**
 * Generates a NCW from a SCW
 * @param scw - the scw
 */
export async function deriveNcwFromScw(scw: HDWallet): Promise<HDWallet> {
  const ncEntropy = await digestSha256(scw.entropy);

  return HDWallet.fromEntropy(new SafeWrapper(ncEntropy));
}

/**
 * Validates a mnemonic phrase
 * @throws InvalidMnemonicError - if the mnemonic is invalid
 * @throws IncorrectMnemonicError - if the mnemonic does not match the active wallet
 */
export async function validatePhrase(
  phrase: SafeWrapper<string>,
  activePublicKey: string,
): Promise<true> | never {
  let publicKey: ArrayBuffer;

  try {
    /**
     * Derive the NCW from the mnemonic to validate the publicKey
     */
    const w = await HDWallet.fromMnemonic(phrase);
    const ncw = await deriveNcwFromScw(w);
    const ncSigner = ncw.signer(ncDerivationPath);
    publicKey = ncSigner.publicKey.slice(1); // Schnorr public keys are 32 bytes, so we remove the first byte according to BIP340
  } catch (e) {
    throw new InvalidMnemonicError();
  }

  if (!Buffer.from(activePublicKey, 'base64').equals(Buffer.from(publicKey))) {
    throw new IncorrectMnemonicError();
  }

  return true;
}
