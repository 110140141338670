import type { ReactNode } from 'react';
import { Component } from 'react';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { logger } from '@noah-labs/shared-logger/browser';
import { ErrorPage } from './ErrorPage';

type StErrorBoundary = {
  hasError?: boolean;
};

// eslint-disable-next-line react/require-optimization
export class ErrorBoundary extends Component<PpWC, StErrorBoundary> {
  constructor(props: PpWC) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown): StErrorBoundary {
    logger.error(error);
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown): void {
    logger.error(error, errorInfo);
  }

  render(): ReactNode {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      return (
        <ErrorPage
          helpText="Refresh the page or go back and try again."
          message="An unknown error occurred"
        />
      );
    }

    /*  eslint-disable-next-line react/destructuring-assignment */
    return this.props.children;
  }
}
