import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from '@noah-labs/fe-shared-feature-auth';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';

export type CxLiveChat = {
  loaded: boolean;
  unreadMessages: boolean;
};

export const LiveChatContext = createContext<CxLiveChat | undefined>(undefined);

function isLiveChatInFullScreenMode(): boolean {
  const el = document.getElementsByClassName('intercom-messenger-frame')[0];
  return window.getComputedStyle(el).getPropertyValue('max-height') === 'none';
}

export function LiveChatProvider({ children }: PpWC): React.ReactElement {
  const [unreadMessages, setUnreadMessages] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { goForward } = useRouter();

  /**
   * subscribe to the signout callback and shutdown
   */
  const { addSignOutSubscriber } = useAuth();

  const shutdown = useCallback(() => loaded && window.Intercom('shutdown'), [loaded]);

  const handleBackButton = useCallback(() => {
    // see: https://github.com/noah-labs/noah-main/pull/5469
    window.Intercom('hide');
    if (isLiveChatInFullScreenMode()) {
      goForward();
    }
  }, [goForward]);

  useEffect(() => addSignOutSubscriber('ic', shutdown), [addSignOutSubscriber, shutdown]);

  const value = useMemo(
    () => ({
      loaded,
      unreadMessages,
    }),
    [loaded, unreadMessages],
  );

  useEffect(() => {
    const intercomApiInterval = setInterval(() => {
      if (typeof window.Intercom !== 'function' || !window.Intercom.booted) {
        return;
      }
      window.Intercom('onShow', (): void => {
        // subscribe to reset the indicator when 'read'
        setUnreadMessages(false);
        window.addEventListener('popstate', handleBackButton);
      });

      window.Intercom('onHide', (): void => {
        window.removeEventListener('popstate', handleBackButton);
      });

      // subscribe to new messages
      window.Intercom('onUnreadCountChange', (unreadCount): void => {
        setUnreadMessages(unreadCount > 0);
      });

      setLoaded(true);
      clearInterval(intercomApiInterval);
    }, 250);

    return (): void => {
      clearInterval(intercomApiInterval);
    };
  }, [handleBackButton]);

  return <LiveChatContext.Provider value={value}>{children}</LiveChatContext.Provider>;
}
