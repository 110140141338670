export enum QRErrors {
  /**
   * NotAllowedError: user denied permission
   * NotFoundError: the device has no attached input devices
   * NotReadableError: couldn't read the device's stream, probably in use by another app.
   * NotSupportedError: usually caused by not secure origins
   */
  NotAllowedError = 'NotAllowedError',
  NotFoundError = 'NotFoundError',
  NotReadableError = 'NotReadableError',
  NotSupportedError = 'NotSupportedError',
}

export function getQRScannerError(error: string): string {
  switch (error) {
    case QRErrors.NotAllowedError:
      return 'Camera has been blocked/disabled.';
    case QRErrors.NotFoundError:
      return 'No camera detected.';
    case QRErrors.NotReadableError:
      return 'Camera in use by another app.';
    case QRErrors.NotSupportedError:
    default:
      return 'Camera is unavailable.';
  }
}
