type TpDisableRefetchRetry = {
  refetchInterval: false;
  refetchIntervalInBackground: false;
  refetchOnMount: false;
  refetchOnReconnect: false;
  refetchOnWindowFocus: false;
  retry: false;
  retryOnMount: false;
};
export const disableRefetchRetry: TpDisableRefetchRetry = {
  refetchInterval: false,
  refetchIntervalInBackground: false,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  retry: false,
  retryOnMount: false,
};
