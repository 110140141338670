import type { StackProps } from '@mui/material';
import { Stack } from '@mui/material';

export type PpButtonGroup = StackProps & {
  fullWidth?: boolean;
};

export function ButtonGroup({
  children,
  direction = 'column',
  fullWidth = direction === 'column' || direction === 'column-reverse',
  spacing = direction === 'column' || direction === 'column-reverse' ? 1 : 0.5,
  sx,
  ...rest
}: PpButtonGroup): React.ReactElement {
  return (
    <Stack
      direction={direction}
      spacing={spacing}
      sx={{
        justifyContent: 'center',
        ...(fullWidth && { width: '100%' }),
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Stack>
  );
}
