import React from 'react';
import type { LinkTypeMap } from '@mui/material';
import { Card, CardContent as CardContentComponent, Link, Stack, Typography } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled, useTheme } from '@mui/material/styles';
import { styledMixins } from '../theme/styledMixins';

export type PpCtaCardFull = {
  ContentSlot?: React.ReactElement;
  IconSlot?: React.ReactElement;
  backgroundImage: string;
  href?: string;
  onClick?: () => void;
  subtitle: string;
  title: string;
};

const CardStyled = styled(Card)<{ backgroundImage: string }>(({ backgroundImage, theme }) => ({
  '::before': {
    ...styledMixins.bgImageCoverNoRepeat(backgroundImage),
    ...styledMixins.stretchAll,
    content: '""',
    height: '100%',
    position: 'absolute',
  },
  backgroundColor: theme.palette.ctaCards.white.dark,
  borderRadius: theme.borderRadius.md,
  boxShadow: theme.palette.ctaCards.white.dark,
  minHeight: '100px',
  position: 'relative',
  width: '100%',
  ...styledMixins.rowCentered,
}));

const CardContentStyled = styled(CardContentComponent)<{
  component?: OverridableComponent<LinkTypeMap>;
  href?: string;
}>(({ theme }) => ({
  '::before': {
    ...styledMixins.stretchAll,
    backgroundColor: theme.palette.grayscale.black80,
    content: '""',
    height: '100%',
    position: 'absolute',
    zIndex: -1,
  },
  flex: 1,
  textAlign: 'left',
  textDecoration: 'none',
  zIndex: 1,
  ...styledMixins.rowCentered,
}));

export function CtaCardFull({
  backgroundImage,
  ContentSlot,
  href,
  IconSlot,
  onClick,
  subtitle,
  title,
}: PpCtaCardFull): React.ReactElement {
  const theme = useTheme();

  const CardText = (
    <React.Fragment>
      <Typography gutterBottom color="grayscale.white" component="h3" variant="paragraphBodyLBold">
        {title}
      </Typography>
      <Typography
        sx={{ color: theme.palette.ctaCards.white.contrastText, whiteSpace: 'pre-wrap' }}
        variant="paragraphBodyS"
      >
        {subtitle}
      </Typography>
    </React.Fragment>
  );

  const CardContentBody = (
    <React.Fragment>
      <Stack flexGrow={1}>
        {CardText}
        {ContentSlot}
      </Stack>
      {IconSlot}
    </React.Fragment>
  );

  const CardContent = href ? (
    <CardContentStyled component={Link} href={href} onClick={onClick}>
      {CardContentBody}
    </CardContentStyled>
  ) : (
    <CardContentStyled>{CardContentBody}</CardContentStyled>
  );

  return <CardStyled backgroundImage={backgroundImage}>{CardContent}</CardStyled>;
}
