import type { SVGProps } from 'react';

export function SvgLogoWithoutFullstop(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg {...props} fill="none" viewBox="0 0 95 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m6.827 1.558 7.497 8.222c.593.671 1.111 1.4 1.546 2.176h.128V1.558h5.951v17.628h-6.44l-7.833-8.211c-.515-.55-1.313-1.617-1.566-2.237h-.13v10.448H0V1.558zm16.255 8.827c0-5.768 4.82-9.417 12.418-9.417 7.596 0 12.39 3.649 12.39 9.417S43.075 19.78 35.5 19.78s-12.418-3.647-12.418-9.394Zm17.855 0c0-2.904-2.138-4.815-5.435-4.815-3.298 0-5.465 1.902-5.465 4.815s2.165 4.813 5.463 4.813 5.438-1.945 5.438-4.813Zm13.42 5.45-1.426 3.35h-7.078l8.504-17.627h8.554l8.5 17.62H64.36l-1.426-3.343zm5.692-6.867c-.31-.746-1.057-2.657-1.367-3.798h-.075c-.336 1.141-1.057 3.052-1.367 3.798l-1.287 3.05h5.383zm27.616 10.218V12.41h-8.81v6.775H72.26V1.558h6.594V8.19h8.811V1.558h6.57v17.628z"
        fill="#F60"
      />
    </svg>
  );
}
