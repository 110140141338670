import type { SVGProps } from 'react';

export function SvgSquareInfo(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="currentColor" height={23.049} rx={5.571} width={23.049} x={0.409} y={0.547} />
      <circle cx={11.706} cy={12.149} fill="#fff" r={8.893} />
      <path
        clipRule="evenodd"
        d="M11.641 10.99c.507 0 .918.41.918.917v4.419a.918.918 0 0 1-1.836 0v-4.419c0-.507.411-.918.918-.918Zm.123-4.072a1.236 1.236 0 1 0 0 2.472 1.236 1.236 0 0 0 0-2.472"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
