import { isBrowser } from './isBrowser';

export function getSafeSessionStorage(): Storage | undefined {
  if (!isBrowser()) {
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return window.sessionStorage ? sessionStorage : undefined;
}

export function getSafeLocalStorage(): Storage | undefined {
  if (!isBrowser()) {
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return window.localStorage ? localStorage : undefined;
}
