function isRunningStandalone(): boolean {
  return window.matchMedia('(display-mode: standalone)').matches || 'standalone' in navigator;
}

const TWA_APP_KEY = 'is-running-twa';

const NATIVE_APP_INJECTED_SAFARI_VERSION = 'Safari/000.0';

export function isRunningTwa(): boolean {
  // this hash comes from startUrl in twa-manifest.json
  const isTwaApp = window.location.hash === '#twa-app';
  const isTwaReferrer = document.referrer.includes('android-app://com.noah.app.twa');
  const isTwaSession = !!sessionStorage.getItem(TWA_APP_KEY);

  if (isTwaSession) {
    return true;
  }

  if (!isTwaApp && !isTwaReferrer) {
    return false;
  }

  sessionStorage.setItem(TWA_APP_KEY, 'true');
  return true;
}

function isRunningIosNative(): boolean {
  return window.navigator.userAgent.includes(NATIVE_APP_INJECTED_SAFARI_VERSION);
}

type AppType = 'twa' | 'ios-native' | 'standalone' | 'browser';

let appType: AppType | undefined;
export function getAppType(): AppType {
  if (appType) {
    return appType;
  }

  if (isRunningTwa()) {
    appType = 'twa';
  } else if (isRunningIosNative()) {
    appType = 'ios-native';
  } else if (isRunningStandalone()) {
    appType = 'standalone';
  } else {
    appType = 'browser';
  }
  return appType;
}
