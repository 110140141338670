import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

// https://day.js.org/docs/en/plugin/duration
dayjs.extend(duration);

type TpConfig = {
  enableTimers: boolean;
  intercom: {
    customLauncherSelector: string;
  };
};

let config: TpConfig | undefined;

export function setConfig(cfg: TpConfig): void {
  config = cfg;
}

export function getConfig(): TpConfig {
  if (!config) {
    throw new Error(
      'config is not set, please call `setConfig()` at the start of your application',
    );
  }
  return config;
}
