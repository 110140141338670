import { FiatPaymentCardScheme } from '@noah-labs/shared-schema-gql';

export function getFiatPaymentCardScheme(
  scheme: string | undefined,
): FiatPaymentCardScheme | undefined {
  switch (scheme?.toLowerCase()) {
    case 'visa':
      return FiatPaymentCardScheme.Visa;
    case 'mastercard':
      return FiatPaymentCardScheme.Mastercard;
    default:
      return undefined;
  }
}
