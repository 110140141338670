import { Grid2 } from '@mui/material';
import type { Grid2Props as GridProps, GridSize } from '@mui/material';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

export type PpFormItem = PpWC & {
  sx?: GridProps['sx'];
  xs?: GridSize;
};

export function FormItem({ children, sx, xs = 12 }: PpFormItem): React.ReactElement {
  return (
    <Grid2 size={{ xs }} sx={sx}>
      {children}
    </Grid2>
  );
}
