import BigNumber from 'bignumber.js';
import { isUndefinedOrNull } from './utils';

type TpAmount = string | number | null | undefined;

export function safeBN(v: TpAmount): BigNumber {
  // BigNumber will return NaN for empty strings, use this for safety
  return new BigNumber(v || 0);
}

export function toInt(amount: unknown, fallbackAmount = 0): number {
  if (typeof amount === 'string') {
    const number = parseInt(amount, 10);
    if (Number.isNaN(number)) {
      return fallbackAmount;
    }
    return number;
  }
  if (typeof amount === 'number') {
    return Math.round(amount);
  }
  return fallbackAmount;
}

export function isNonZero(value: TpAmount): value is string | number {
  return value !== '' && value !== '0' && !isUndefinedOrNull(value);
}

/**
 * zeroToBlankOrValue returns a blank string if the value is a 'zero' value
 * zeros: undefined | null | 0 | '' | '0'
 */
export function zeroToBlankOrValue(value: TpAmount): string {
  if (!isNonZero(value)) {
    return '';
  }
  return value.toString();
}
