import { Fragment } from 'react';
import { Divider, Stack } from '@mui/material';
import { ListItemCard } from '../lists/ListItem';
import { ConsentOption } from './ConsentOption';

function joinDestNames(destinations: TpDestination[]): string {
  return destinations.map((destination) => destination.name).join(', ');
}

type TpDestination = {
  name: string;
};
type PpCookieOptions = {
  advertising: TpDestination[];
  functional: TpDestination[];
  marketingAnalytics: TpDestination[];
};
export function CookieOptions({
  advertising,
  functional,
  marketingAnalytics,
}: PpCookieOptions): React.ReactElement {
  const functionalTools = joinDestNames(functional);
  return (
    <ListItemCard sx={{ padding: 2 }}>
      <Stack divider={<Divider />} spacing={2}>
        {marketingAnalytics.length !== 0 && (
          <ConsentOption
            label={{
              primary: <Fragment>Marketing &amp; Analytics</Fragment>,
              secondary: (
                <Fragment>
                  These allow us to analyze your use of NOAH so that we can improve our performance
                  and provide you with a better customer experience on NOAH. <strong>Tools:</strong>{' '}
                  {joinDestNames(marketingAnalytics)}
                </Fragment>
              ),
            }}
            name="Cookies.marketingAndAnalytics"
          />
        )}
        {advertising.length !== 0 && (
          <ConsentOption
            label={{
              primary: <Fragment>Advertising</Fragment>,
              secondary: (
                <Fragment>
                  These allow us to personalize and measure the effectiveness of advertising on NOAH
                  and other websites. For example, we may serve you a personalized ad based on the
                  pages you visit on NOAH. <strong>Tools:</strong> {joinDestNames(advertising)}
                </Fragment>
              ),
            }}
            name="Cookies.advertising"
          />
        )}
        <ConsentOption
          checked
          readOnly
          label={{
            primary: <Fragment>Functional (required)</Fragment>,
            secondary: (
              <Fragment>
                These enable core NOAH functionality such as security, network management, live chat
                and accessibility.{functionalTools && <strong> Tools: </strong>} {functionalTools}
              </Fragment>
            ),
          }}
          name="Cookies.functional"
        />
      </Stack>
    </ListItemCard>
  );
}
