import { Fragment } from 'react';
import type { SxProps, Theme } from '@mui/material';
import { Typography } from '@mui/material';
import type { PpWOC } from '@noah-labs/fe-shared-ui-shared';
import { Helmet } from 'react-helmet';
import { AppLogo } from '../images/AppLogo';
import { CenteredFullPage } from '../layout/CenteredFullPage';
import { CircularSpinner } from './CircularSpinner';

export type PpLoadingPage = PpWOC & {
  message?: string;
  sx?: SxProps<Theme>;
};

export function LoadingPage({ children, message, sx }: PpLoadingPage): React.ReactElement {
  return (
    <Fragment>
      <Helmet>
        <title>{message || 'NOAH'}</title>
      </Helmet>
      <CenteredFullPage sx={sx}>
        <AppLogo />
        <CircularSpinner sx={{ pt: 6 }} />
        {message && <Typography sx={{ pt: 5, textAlign: 'center' }}>{message}</Typography>}
        {children}
      </CenteredFullPage>
    </Fragment>
  );
}
