import type { FormControlLabelProps, RadioGroupProps as MuiRadioGroupProps } from '@mui/material';
import { RadioGroup as MuiRadioGroup } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export type PpRadioGroup = Omit<MuiRadioGroupProps, 'id' | 'name'> &
  Pick<FormControlLabelProps, 'label'> & {
    name: string;
  };

export function RadioGroup({ name, ...rest }: PpRadioGroup): React.ReactElement {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }): React.ReactElement => <MuiRadioGroup id={name} {...rest} {...field} />}
    />
  );
}
