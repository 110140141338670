import { createFontFace } from '../../src/lib/createFontFace';

const path = 'gt-america-extended';
const family = 'GT America Extended';

export const normal900 = createFontFace({
  family,
  path,
  style: 'normal',
  variant: 'black',
  weight: 900,
});

export const normal700 = createFontFace({
  family,
  path,
  style: 'normal',
  variant: 'bold',
  weight: 700,
});

export const normal500 = createFontFace({
  family,
  path,
  style: 'normal',
  variant: 'medium',
  weight: 500,
});
