import { Box, Container } from '@mui/material';
import type { CSSObject } from '@mui/material';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

export type PpSceneHeader = PpWC & {
  dense?: boolean;
  textAlign?: CSSObject['textAlign'];
};

export function SceneHeader({
  children,
  dense = false,
  textAlign,
}: PpSceneHeader): React.ReactElement {
  // we use padding to ensure any background colours are 'full bleed'
  const pt = dense ? 2 : 4;
  return (
    <Box component="header" sx={{ pt, textAlign }}>
      <Container maxWidth="sm">{children}</Container>
    </Box>
  );
}
