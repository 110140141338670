/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react';
import { ListItem, ListItemCard } from '../lists/ListItem';
import type { PpSkeletonListItemContent } from '../lists/SkeletonListItemContent';
import { SkeletonListItemContent } from '../lists/SkeletonListItemContent';

type PpSkeletonCards = PpSkeletonListItemContent & {
  rows?: number;
};
export function SkeletonCards({ rows = 3, ...rest }: PpSkeletonCards): React.ReactElement {
  return (
    <Fragment>
      {new Array(rows).fill(null).map((_, index) => (
        <ListItem key={index} data-qa="loading">
          <ListItemCard>
            <SkeletonListItemContent {...rest} />
          </ListItemCard>
        </ListItem>
      ))}
    </Fragment>
  );
}
