import type { SVGProps } from 'react';

export function SvgSquareTick(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="currentColor" height={23.049} rx={5.571} width={23.049} x={0.409} y={0.547} />
      <circle cx={11.706} cy={12.149} fill="#fff" r={8.893} />
      <path
        clipRule="evenodd"
        d="M16.363 8.66c.477.379.556 1.073.177 1.55l-4.738 5.955a1.103 1.103 0 0 1-1.645.092l-2.542-2.553a1.103 1.103 0 0 1 1.563-1.556l1.669 1.675 3.966-4.987a1.103 1.103 0 0 1 1.55-.176"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
