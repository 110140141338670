import type { SVGProps } from 'react';

export function SvgContact(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={12.123} cy={12.04} fill="#E3E3E5" r={10.623} />
      <mask
        height={17}
        id="contact_svg__a"
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
        width={21}
        x={2}
        y={6}
      >
        <path d="M2.086 16.171h20.065v6.492H2.086z" fill="#fff" />
        <ellipse cx={12.076} cy={11.998} fill="#fff" rx={5.27} ry={5.269} />
      </mask>
      <g mask="url(#contact_svg__a)">
        <circle cx={12.119} cy={12.042} fill="currentColor" r={10.623} />
      </g>
    </svg>
  );
}
