export const brandColors = {
  background: {
    overlayLightTransparent: 'rgba(38,38,38, 0.8)',
  },
  ctaCards: {
    blue: {
      contrastText: '#BCB1FF',
      dark: '#5C42FF',
    },
    brown: {
      contrastText: '#FCB07D',
      dark: '#705544',
    },
    pink: {
      contrastText: '#E394FF',
      dark: '#682568',
    },
  },
  gradients: {
    darkHorizontal:
      'linear-gradient(180deg, rgba(43, 43, 43, 0) 0%, #2B2B2B 40%, #2B2B2B 66%, rgba(43, 43, 43, 0) 100%)',
    darkRadial: `radial-gradient(32% 32% at 50% 50%, rgba(0, 0, 0, 0.8) 33%, rgba(0, 0, 0, 0) 100%);`,
    gray: 'linear-gradient(134deg, #1c1c1c -20%, #383838 126%)',
    primary: 'linear-gradient(326deg, #FF6B00 4%, #FF3C00 144%)',
    white: 'linear-gradient(135deg, rgba(168, 168, 168, 0.94) 28%, rgba(168, 168, 168, 0.03) 141%)',
  },
};
