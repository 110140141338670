import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { useAuth } from '@noah-labs/fe-shared-feature-auth';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { getConfig } from '../config';

export function DatadogProvider({ children }: PpWC): React.ReactElement {
  const { appStage, commitHash, datadog } = getConfig();
  const { data } = useAuth();

  useEffect(() => {
    if (!datadog.enabled || !data?.userId) {
      return;
    }

    datadogLogs.setUser({
      id: data.userId,
    });
  }, [datadog.enabled, data?.userId]);

  useEffect(() => {
    if (!datadog.enabled) {
      return;
    }

    datadogLogs.init({
      clientToken: datadog.token,
      env: appStage.toLowerCase(),
      forwardErrorsToLogs: true,
      service: 'fe-web',
      sessionSampleRate: 100,
      version: commitHash,
    });
  }, [appStage, commitHash, datadog.enabled, datadog.token]);

  return children as ReactElement;
}
