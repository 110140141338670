type TpConfig = {
  apiHost: string;
  cdnUrl: string;
  enabled: boolean;
  identify: boolean;
  track: boolean;
  writeKey: string;
};

let config: TpConfig | undefined;

export function setConfig(cfg: TpConfig): void {
  config = cfg;
}

export function getConfig(): TpConfig {
  if (!config) {
    throw new Error(
      'config is not set, please call `setConfig()` at the start of your application',
    );
  }
  return config;
}
