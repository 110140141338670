import type { TpSegmentCategories } from '@noah-labs/fe-shared-data-access-analytics';
import type { MiddlewareFunction } from '@segment/analytics-next/dist/types/plugins/middleware';

/**
 * adds the user's consent preferences to every Segment call
 * see: https://github.com/segmentio/consent-manager/blob/master/src/consent-manager-builder/analytics.ts
 */
export function getConsentMiddleware(categoryPreferences: TpSegmentCategories): MiddlewareFunction {
  return ({ next, payload }) => {
    if (payload.obj.context) {
      // eslint-disable-next-line no-param-reassign
      payload.obj.context.consent = categoryPreferences;
    }
    next(payload);
  };
}
