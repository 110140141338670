import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NoahSquareIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/NoahSquareIcon';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { coreThemeConstants } from '../theme/coreThemeConstants';

export type PpSidebarNav = PpWC & {
  title?: string;
};

export function SidebarNav({ children, title }: PpSidebarNav): React.ReactElement {
  const theme = useTheme();

  const styles = {
    icon: css`
      color: ${theme.palette.brand.main};
      font-size: ${theme.typography.pxToRem(32)};
      margin-right: ${theme.spacing(2)};
    `,
    toolbar: css`
      margin-bottom: ${theme.spacing(5)};
    `,
    wrapper: css`
      position: fixed;
      width: calc(100% / 12 * ${coreThemeConstants.sidebarColumns});
      padding: ${theme.spacing(2)};
    `,
  };

  return (
    <Stack css={styles.wrapper}>
      <Stack css={styles.toolbar} direction="row">
        <NoahSquareIcon css={styles.icon} />
        {title && (
          <Typography noWrap variant="subHeadingM">
            {title}
          </Typography>
        )}
      </Stack>
      {children}
    </Stack>
  );
}
