import type { SxProps, Theme } from '@mui/material';
import { Box, Stack, Typography } from '@mui/material';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import type { CoreTypographyPropsVariantOverrides } from '../theme/types';

export type PpListSection = PpWC & {
  TitleEndSlot?: React.ReactNode;
  dense?: boolean;
  sx?: SxProps<Theme>;
  title?: string;
  titleColor?: string;
  titleVariant?: keyof CoreTypographyPropsVariantOverrides;
};

export function ListSection({
  children,
  dense,
  sx,
  title,
  titleColor = 'text.primary',
  TitleEndSlot,
  titleVariant = 'paragraphBodyMBold',
}: PpListSection): React.ReactElement {
  return (
    <Box component="section" sx={sx}>
      <Stack direction="row" justifyContent="space-between">
        {title && (
          <Typography
            color={titleColor}
            component="h3"
            sx={dense ? { mb: 1 } : { mb: 1.5 }}
            variant={titleVariant}
          >
            {title}
          </Typography>
        )}
        {TitleEndSlot}
      </Stack>
      {children}
    </Box>
  );
}
