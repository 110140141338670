import { importPublicKey } from './utils';

export const sdKeyPreprod = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkU1R+F70aiWkOMBzNFN6
IyMAIeksGjt3Qu5bYc70nutSRlkR7Ch8aryc/GRe/QkPf0+5MMj7nkoHeylPY0HZ
5ADyaxR1MqRcyw61fNNRWv29a8LyhBv5lwSrG44LkdcM0X6CJiJdnnOYtZfcHq89
NP1oLksOAZQ0oqaKgIPUqIM9usp1EDewYBpqaYGkCijOFEGKJrX9SNKjP/exEjpc
hlnhRHdMO+vzUgu8B2jc+MYl5pxbdJoBBZGW+89wsxN1vQr5nGkcyWT5cmy5Pgs9
m17lppFd7JFuQnAy8LfiYyLqghYWRy80jRMuCfFsWsbWibVH6qzS4t5h6Uafrtf5
ewIDAQAB
-----END PUBLIC KEY-----`;
export const sdKeyIdPreprod = '67569016-ab7d-4063-9e78-e903d8675263';

export const sdKeyProd = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxTOu5Cmc1DopMsKZru04
LOt2YBnzIks4s8Jhyb8zYpRfnvc1/Q1c4YP4jeZws+OXWAhoicArlnI1nK6w6B4Y
ke1imhpOUw1zhhIybiVyi+ke6Df1YTq09wuSS31hz2t0zQyQ1jA0x/pIyBbGOVLv
/Obzf/Gq9vl2bD4+YiE4rPrC6CuSYonm0xuXT6NGOEcfBhtL0Z36LFW7xc65wu7b
H/M4u4ZYrklEkXsSRtkaYr3jBppAy+8GBUNK9hcDOAhpiUBmukKuPKeNRgIfai1W
64mdc0eJn6FZWW2Kuz68tHuiXVufkYZ3vFtstlzCj1oW8PyQdvetkVFKv3eTVRJ1
OwIDAQAB
-----END PUBLIC KEY-----`;
export const sdKeyIdProd = 'f5490356-19f2-49e6-9069-1c9c1b7b745a';

export const appInputKeyPreprod = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA7zjqJqAPrQXDvVrWjiIB
y4vgUuBUeTUDg7HYKD6bzxjJpyMeGJ98/BgkhTdfLggBg9WVrZOdhKXylPJG/y/S
uRHsG9746DQBlMJToJFcBijAHfiDi3FNpv7kTnP27TYSMFoJ9L1lu/RLAmtv3uMK
I6Go6ktdo0GLvjkdmDbJNTxt0nuo8+IjjURijZAkuCZT5DcIaLmustILyLlWQU8H
tenduY8GrYTQqoqzG4oTBqly2NRb9fjZn4rTJ0dPb68lYnX1d/KHv6Gh018cR+wV
nqcvGMModEO3ziR53fzNzv88nWvH8bH10LyCBMvuxoKWQs4/zbTNWOlbAB2BGWed
fQIDAQAB
-----END PUBLIC KEY-----`;
export const appInputKeyIdPreprod = 'e2b47114-c4cd-409e-a1d6-900fe6295b95';

export const appInputKeyProd = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvOSG4+rZGPxzH3Idv6HG
rNcggz8UJ1HbNaUeKsYTnksOh92L90SoE23Tg2s4rEaYZhh+W6ByNStWl2tek9pl
O2r2CG07OSZyd9r2bgPcailm1VNGbQ45AOm6jt2oDi9C0JfgVLjR4Jc5eg/annvT
ewRqZLRIMFataNyyLLx7xisXH3gG4xQQSyNi2MCmo4nJamoCGVgbs2MICLwuydMR
F5DaPv9xaxXnDZFjFzeNWKT8Di1iIB4B7XioG2Dc1jqs3El5q+CmZvajov0QqmZE
6rfpZOebwBHlZqpobNdSkj6Wn57zqPbiqoRvubtNMep4Uj5Ko/1MZ0tTb7jbGjfe
5wIDAQAB
-----END PUBLIC KEY-----`;
export const appInputKeyIdProd = '82a1f594-7cae-4771-a910-6116efb645a5';

function getSdKey(isProd: boolean): [string, string] {
  if (isProd) {
    return [sdKeyIdProd, sdKeyProd];
  }
  return [sdKeyIdPreprod, sdKeyPreprod];
}

function getAppInputKey(isProd: boolean): [string, string] {
  if (isProd) {
    return [appInputKeyIdProd, appInputKeyProd];
  }
  return [appInputKeyIdPreprod, appInputKeyPreprod];
}

type TpGetKey = {
  isProd: boolean;
  type: 'secretDocument' | 'appInput';
};
/**
 * Returns the key id and the key for the given type and environment
 */
export async function getKey({ isProd, type }: TpGetKey): Promise<[string, CryptoKey]> {
  let pem;
  let keyId;
  switch (type) {
    case 'secretDocument':
      [keyId, pem] = getSdKey(isProd);
      break;
    case 'appInput':
      [keyId, pem] = getAppInputKey(isProd);
      break;
    default:
      throw new Error('Invalid key type');
  }
  return [keyId, await importPublicKey(pem)];
}
