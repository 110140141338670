import { Fragment } from 'react';
import { ListItemCard } from '../lists/ListItem';
import { ConsentOption } from './ConsentOption';

export function ContactOptions(): React.ReactElement {
  return (
    <ListItemCard sx={{ padding: 2 }}>
      <ConsentOption
        label={{
          primary: <Fragment>Email</Fragment>,
          secondary: (
            <Fragment>
              We would like to inform you about NOAH point giveaways and promotional offers as well
              as new features and updates. We will never sell your data.
            </Fragment>
          ),
        }}
        name="EmailContact"
      />
    </ListItemCard>
  );
}
