/* eslint-disable max-classes-per-file */
export class IncorrectPinError extends Error {
  constructor(attempts: number) {
    super(`Wrong PIN, please try again. You have ${attempts} attempts left.`);
    Object.setPrototypeOf(this, IncorrectPinError.prototype);
  }
}

export class LockedPinError extends Error {
  constructor() {
    super('PIN is locked. Please click on Forgot PIN to begin the recovery process.');
    Object.setPrototypeOf(this, LockedPinError.prototype);
  }
}

export class InvalidMnemonicError extends Error {
  constructor() {
    super('The entered phrase does not match. Please double-check and try again.');
    Object.setPrototypeOf(this, InvalidMnemonicError.prototype);
  }
}

export class IncorrectMnemonicError extends Error {
  constructor() {
    super('The phrase you entered does not match your wallet.');
    Object.setPrototypeOf(this, IncorrectMnemonicError.prototype);
  }
}

export class InvalidPinError extends Error {
  constructor() {
    super('Invalid PIN');
    Object.setPrototypeOf(this, InvalidPinError.prototype);
  }
}

export class GenericPINError extends Error {
  constructor() {
    super('Sorry, something went wrong when validating your PIN.');
    Object.setPrototypeOf(this, InvalidPinError.prototype);
  }
}
