//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgSwitchArrows } from '../icons/SwitchArrows';

export function SwitchArrowsIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>,
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgSwitchArrows} data-qa="SwitchArrowsIcon">
      <svg />
    </SvgIcon>
  );
}
