import type { SVGProps } from 'react';

export function SvgLogo(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg {...props} fill="none" viewBox="0 0 225 42" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#logo_svg__a)" fill="currentColor">
        <path d="m15.084 1.234 16.564 18.168a27.7 27.7 0 0 1 3.416 4.807h.283V1.234h13.15v38.95h-14.23L16.96 22.041c-1.138-1.214-2.902-3.572-3.46-4.942h-.288v23.083H0V1.234zm35.914 19.507C50.998 7.996 61.65-.066 78.435-.066c16.784 0 27.378 8.062 27.378 20.807S95.17 41.496 78.434 41.496c-16.735 0-27.436-8.058-27.436-20.755m39.452 0c0-6.416-4.725-10.638-12.011-10.638s-12.073 4.2-12.073 10.638 4.783 10.634 12.069 10.634c7.285 0 12.014-4.296 12.014-10.634ZM120.1 32.78l-3.15 7.403h-15.638L120.1 1.234H139l18.783 38.932H142.2l-3.15-7.387zm12.578-15.173c-.684-1.647-2.336-5.87-3.02-8.392h-.166c-.743 2.523-2.336 6.745-3.02 8.392l-2.844 6.741h11.894zm61.016 22.576V25.215h-19.467v14.968h-14.571V1.234h14.571v14.657h19.467V1.234h14.517v38.95h-14.517Zm24.408 0c3.752 0 6.795-2.93 6.795-6.545 0-3.616-3.043-6.546-6.795-6.546-3.753 0-6.795 2.93-6.795 6.546s3.042 6.545 6.795 6.545" />
      </g>
      <defs>
        <clipPath id="logo_svg__a">
          <path d="M0 0h225v42H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
