import type { ListItemTextProps } from '@mui/material';
import { ListItemText } from '@mui/material';

export type PpDefaultListItemText = ListItemTextProps & {
  noWrap?: boolean;
};

/* DefaultListItemText is needed as workaround for known bug in MUI's ListItemText - resetting primaryTypographyProps and secondaryTypographyProps in theme's defaultProps is not working */
export function DefaultListItemText({
  noWrap = true,
  ...props
}: PpDefaultListItemText): React.ReactElement {
  const mainTextProps: ListItemTextProps = {
    ...props,
    primaryTypographyProps: {
      noWrap,
      variant: 'paragraphBodySBold',
      ...props.primaryTypographyProps,
    },
    secondaryTypographyProps: {
      noWrap,
      variant: 'paragraphBodyS',
      ...props.secondaryTypographyProps,
    },
    sx: { margin: 0 },
  };

  return <ListItemText {...mainTextProps} />;
}
