import type { TpFeFeature, TpFeFeatureFlag } from '@noah-labs/fe-shared-data-access-user';
import { useUserInitUi } from './useUserInitUi';

export function useFeature(feature: TpFeFeature): {
  feature: TpFeFeatureFlag | undefined;
  isFetched: boolean;
} {
  const { data: userData, isFetched } = useUserInitUi();
  return {
    feature: userData?.userProfile.features.get(feature),
    isFetched,
  };
}
