import type { SVGProps } from 'react';

export function SvgCheckbox(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="currentColor" height={23} rx={7.5} width={23} x={0.5} y={0.5} />
      <rect height={23} rx={7.5} stroke="#8A8A8A" width={23} x={0.5} y={0.5} />
    </svg>
  );
}
