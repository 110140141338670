export const categories = {
  advertising: ['Advertising'],
  functional: [
    'CRM',
    'Customer Success',
    'Deep Linking',
    'Helpdesk',
    'Livechat',
    'Performance Monitoring',
    'Personalization',
    'SMS & Push Notifications',
    'Security & Fraud',
  ],
  marketingAndAnalytics: [
    'A/B Testing',
    'Analytics',
    'Attribution',
    'Email',
    'Enrichment',
    'Heatmaps & Recordings',
    'Raw Data',
    'Realtime Dashboards',
    'Referrals',
    'Surveys',
    'Tag Managers',
    'Video',
  ],
};
