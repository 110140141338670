import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import type { TypographyProps } from '@mui/material';
import dayjs from 'dayjs';
import { getConfig } from '../../config';
import { TextOrSkeleton } from '../typography/TextOrSkeleton';

export type PpCountdown = {
  expiredLabel?: string;
  expiryTime: string | number | null | undefined;
  prefixLabel?: string;
  suffixLabel?: string;
};

function getTimeDiff(expiryTime: string | number | null | undefined): number {
  return dayjs(expiryTime).diff(dayjs());
}

function formatTime(timeDiff: number): string {
  if (timeDiff < dayjs.duration({ hours: 1 }).asMilliseconds()) {
    return 'mm:ss';
  }
  if (timeDiff < dayjs.duration({ days: 1 }).asMilliseconds()) {
    return 'H[h] mm[m]';
  }
  return 'D[d] H[h] mm[m]';
}

/**
 * format: https://day.js.org/docs/en/display/format
 */
export function Countdown({
  expiredLabel,
  expiryTime,
  prefixLabel,
  suffixLabel,
  ...typographyProps
}: PpCountdown & TypographyProps): React.ReactElement {
  const { enableTimers } = getConfig();
  const initialTimeDiff = getTimeDiff(expiryTime);
  const [timeDiff, setTimeDiff] = useState<number>(initialTimeDiff);

  useEffect(() => {
    if (!enableTimers) {
      return undefined;
    }

    const interval = setInterval(() => {
      const diff = getTimeDiff(expiryTime);
      setTimeDiff(diff);

      if (diff <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [enableTimers, expiryTime]);

  if (timeDiff <= 0) {
    return <Typography {...typographyProps}>{expiredLabel}</Typography>;
  }

  return (
    <Typography {...typographyProps}>
      {prefixLabel}{' '}
      <TextOrSkeleton>{dayjs.duration(timeDiff).format(formatTime(timeDiff))}</TextOrSkeleton>{' '}
      {suffixLabel}
    </Typography>
  );
}
