import { forwardRef } from 'react';
import type { TextFieldProps } from '@mui/material';
import { InputAdornment, TextField } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export type PpInputFieldAtom = Omit<TextFieldProps, 'error' | 'id' | 'name'> & {
  dataQa?: string;
  endSlot?: React.ReactNode;
  errorMessage?: string;
  labelSrOnly?: boolean;
  name: string;
  startSlot?: React.ReactNode;
};

export const InputFieldAtom = forwardRef<HTMLDivElement, PpInputFieldAtom>(
  (
    {
      dataQa,
      endSlot,
      errorMessage,
      helperText,
      label,
      labelSrOnly = false,
      name,
      placeholder,
      slotProps,
      startSlot,
      ...rest
    },
    ref,
  ) => {
    const textLabel = typeof label === 'string' ? label : undefined;

    return (
      <TextField
        {...rest}
        ref={ref}
        error={Boolean(errorMessage)}
        helperText={errorMessage || helperText}
        id={name}
        label={label}
        name={name}
        placeholder={placeholder ?? textLabel}
        slotProps={{
          htmlInput: {
            ...slotProps?.htmlInput,
            'data-qa': dataQa ? `${dataQa}-input` : 'input',
          },

          input: {
            ...slotProps?.input,
            ...(endSlot && {
              endAdornment: <InputAdornment position="end">{endSlot}</InputAdornment>,
            }),
            ...(startSlot && {
              startAdornment: <InputAdornment position="start">{startSlot}</InputAdornment>,
            }),
          },

          inputLabel: {
            ...slotProps?.inputLabel,
            sx: labelSrOnly ? visuallyHidden : undefined,
          },
        }}
      />
    );
  },
);
InputFieldAtom.displayName = InputFieldAtom.name;
