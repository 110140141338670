import { getAppType } from '@noah-labs/fe-shared-ui-shared';
import type { MiddlewareFunction } from '@segment/analytics-next/dist/types/plugins/middleware';

export function getAppTypeMiddleware(): MiddlewareFunction {
  return ({ next, payload }) => {
    if (payload.obj.context) {
      // eslint-disable-next-line no-param-reassign
      payload.obj.context.app = { name: getAppType() };
    }
    next(payload);
  };
}
