/* eslint-disable no-param-reassign */
import tokens from './tokens.json';
import type { TpFigmaTypography } from './types';

const { global: Tokens } = tokens;
export { Tokens };

type TokensFragment = {
  [s: string]: TokensFragment | string;
};

/**
 * Seeks a color value from a path in the tokens file recursively
 * i.e ColourUsage.Primary.Main => BrandColours.Greyscale.800 => #262626
 */
export function getValueFromPath(path: string): string {
  // strip {} if present
  if (typeof path === 'string' && path.startsWith('{') && path.endsWith('}')) {
    return getValueFromPath(path.substring(1, path.length - 1));
  }

  // don't strip the path string if it's a letter spacing value
  const letterSpacingRegex = /^-0\.\d+em$/;
  if (letterSpacingRegex.test(path)) {
    return path;
  }
  const pathSegments = path.split('.');

  // if there is only one segment, return it because it must be a value and not a path
  if (pathSegments.length === 1) {
    return pathSegments[0];
  }

  let currentObj: TokensFragment = Tokens;

  // construct new value from segments
  pathSegments.forEach((segment) => {
    if (!Object.keys(currentObj).includes(segment)) {
      throw new Error(`Invalid path: ${path}`);
    }
    currentObj = currentObj[segment] as TokensFragment;
  });
  const { value } = currentObj as { value?: string };
  // keep going if the value is a path
  if (value && value.startsWith('{') && value.endsWith('}')) {
    return getValueFromPath(value.substring(1, value.length - 1));
  }

  return value as string;
}

/**
 * Invokes a function on every value in an object recursively
 * Used in conjunction with flattenValues to surface nested values
 */
function recursiveTraversal(obj: TokensFragment, fn: (val: string) => string): TokensFragment {
  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    if (typeof val === 'object') {
      recursiveTraversal(val, fn);
    } else {
      obj[key] = fn(val);
    }
  });
  return obj;
}

/**
 * Flattens a record of properties into a record of values
 */
export function flattenValues<T extends string, K extends TokensFragment | string>(
  values: Record<T, { type: string; value: TokensFragment | string }>,
): Record<T, K> {
  const result = {} as Record<T, K>;
  Object.keys(values).forEach((key: string) => {
    const { value } = values[key as T];
    if (typeof value === 'object' && typeof value !== 'string') {
      recursiveTraversal(value, getValueFromPath);
    }

    result[key as T] = values[key as T].value as K;
  });
  return result;
}

export function convertTypographyStyleFromFigma(
  typographyVariant: TpFigmaTypography,
): React.CSSProperties {
  const { fontSize, fontWeight, letterSpacing, lineHeight } = typographyVariant;
  const updatedLineHeight = parseInt(lineHeight, 10) / 100;
  const updatedFontWeight = parseInt(fontWeight, 10);
  const updatedFontSize = `${+fontSize / 16}rem`;
  return {
    fontSize: updatedFontSize,
    fontStyle: 'normal',
    fontWeight: Number.isNaN(updatedFontWeight) ? 500 : updatedFontWeight,
    letterSpacing,
    lineHeight: Number.isNaN(updatedLineHeight) ? 1 : updatedLineHeight,
  };
}
