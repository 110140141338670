import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { logger } from '@noah-labs/shared-logger/browser';
import type { SubAccountResponse } from '@noah-labs/shared-schema-gql';
import { webConfigBrowser } from '../../../webConfigBrowser';
import type { TpSubscribeQuery } from '../data/subscriptions';
import { subscriptions } from '../data/subscriptions';
import { useSubscriptionJwtQuery } from '../data/subscriptions.generated';

export type TpConnectionState = {
  connected: boolean;
};

type TpWsEvent = {
  data: string;
};

type TpWsError = {
  errorType: 'string';
  message: 'string';
};

export type TpSubData = {
  onAccountUpdate: SubAccountResponse;
};

export type TpWsData = {
  id: string;
  payload?: {
    data?: TpSubData;
    errors?: Array<TpWsError>;
  };
  type: 'ka' | 'connection_ack' | 'start_ack' | 'data' | 'complete' | 'error';
};

export const initialState: TpConnectionState = {
  connected: false,
};

/**
 * Outboung request to initialize connection after handshake
 */
const connInitRequest = { type: 'connection_init' };

const { baseUrl, baseWsUrl, realtimePath } = webConfigBrowser.graphql;

export type CxSubscription = {
  connected: boolean;
  subscribe: (query: TpSubscribeQuery) => void;
};

export const SubscriptionContext = createContext<CxSubscription | undefined>(undefined);

/**
 * This is a websocket client for the AppSync Realtime API
 * https://docs.aws.amazon.com/appsync/latest/devguide/real-time-websocket-client.html
 */
export function SubscriptionProvider({ children }: PpWC): React.ReactElement {
  const { data: userData } = useUserInitUi();
  const userHasProfile = Boolean(userData?.userProfile.UsernameDisplay);
  const { data: tokenData } = useSubscriptionJwtQuery(undefined, {
    enabled: userHasProfile,
  });

  const [subscribed, setSubscribed] = useState(false);

  const [state, dispatch] = useReducer(
    (st: TpConnectionState, data: TpWsData): TpConnectionState => {
      switch (data.type) {
        /**
         * conencted to Appsync
         */
        case 'connection_ack':
          return { ...st, connected: true };
        /**
         * subscription added
         */
        case 'start_ack':
          return st;
        /**
         * subscription removed
         */
        case 'complete':
          return st;
        /**
         * subscription error
         */
        case 'error':
          logger.error('Subscription error', data.payload);
          return st;
        /**
         * subscription data
         */
        case 'data': {
          const { id } = data;
          switch (id) {
            case 'onAccountUpdate':
              // TODO: enable once we confirm websocket connection in prod
              // onAccountUpdate(queryClient, payload);
              break;
            default:
              logger.error(`Unknown subscription id: ${id}`);
              break;
          }
          return st;
        }
        default:
          return st;
      }
    },
    initialState,
  );

  const ws = useRef<WebSocket | null>(null);

  const isReadyToSubscribe = userHasProfile && tokenData && state.connected;

  const subscribe = useCallback(
    (query: TpSubscribeQuery) => {
      if (!tokenData?.subscriptionJwt.Token) {
        logger.error('No subscription token');
        return;
      }
      const sub = {
        id: query.id,
        payload: {
          data: JSON.stringify(query),
          extensions: {
            authorization: {
              authorization: tokenData.subscriptionJwt.Token,
              host: new URL(baseUrl).host,
            },
          },
        },
        type: 'start',
      };
      ws.current?.send(JSON.stringify(sub));
    },
    [tokenData?.subscriptionJwt.Token],
  );

  useEffect(() => {
    if (!userHasProfile || state.connected) {
      return;
    }

    const url = new URL(realtimePath, baseWsUrl).toString();
    ws.current = new WebSocket(url, ['graphql-ws']);

    /**
     * Once connection is established, send connection init message
     */
    ws.current.onopen = (): void => {
      ws.current?.send(JSON.stringify(connInitRequest));
    };

    ws.current.onmessage = (e: TpWsEvent): void => {
      const data = JSON.parse(e.data) as TpWsData;
      dispatch(data);
    };
  }, [state.connected, userHasProfile]);

  useEffect(() => {
    if (!isReadyToSubscribe || subscribed) {
      return;
    }

    subscriptions.forEach(subscribe);
    setSubscribed(true);
  }, [isReadyToSubscribe, subscribe, subscribed]);

  const value = useMemo(
    () => ({
      connected: state.connected,
      subscribe,
    }),
    [state.connected, subscribe],
  );

  return <SubscriptionContext.Provider value={value}>{children}</SubscriptionContext.Provider>;
}
