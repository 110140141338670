import type { SVGProps } from 'react';

export function SvgSell(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 12c0 6.628-5.373 12-12 12S0 18.628 0 12C0 5.373 5.373.001 12 .001s12 5.372 12 12Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M12 21.6a9.6 9.6 0 1 0-9.6-9.599c0 5.3 4.298 9.599 9.6 9.599m0 2.4c6.627 0 12-5.372 12-12C24 5.373 18.627.001 12 .001s-12 5.372-12 12C0 18.627 5.373 24 12 24"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M14.779 15.086c1.773 0 3.21-.853 3.21-1.905 0-1.051-1.437-1.904-3.21-1.904s-3.21.853-3.21 1.905c0 1.051 1.437 1.904 3.21 1.904"
        fill="#fff"
      />
      <path
        clipRule="evenodd"
        d="M11.505 14.102c-.016.613-.016.692-.016 1.195 0 .268.252.457.488.646.787.66 1.668.787 2.754.802 1.275.032 2.975-.598 3.226-1.463.11-.362.032-.41.047-1.086-.047.11-.094.205-.157.299-1.196 1.684-5.838 1.558-6.342-.393"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.505 16.102c.048.74.016.44.048 1.007.031.63 1.652 1.259 3.21 1.243 1.338-.016 3.053-.52 3.1-1.259.016-.315.142-.566.094-1.007-1.164 1.62-5.397 1.637-6.452.016"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M9.956 9.033c1.773 0 3.21-.853 3.21-1.904 0-1.052-1.437-1.904-3.21-1.904s-3.21.852-3.21 1.904c0 1.051 1.437 1.904 3.21 1.904"
        fill="#fff"
      />
      <path
        clipRule="evenodd"
        d="M6.699 8.035c-.016.33-.063.693 0 .913.251 1.101 1.872 1.715 3.289 1.778 1.668.047 3.1-.881 3.131-1.495.016-.378.079-.755.079-1.164-.268.865-1.605 1.542-3.242 1.558-1.29 0-2.801-.394-3.257-1.59"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.699 9.772c0 .473-.047.661 0 .882.236 1.117 1.888 1.794 3.304 1.794.048 0 .708.031.724.015.063-.251.095-.456.252-.629.472-.598 1.306-.897 2.014-1.102.048-.015.142-.834.048-.66-.205.361-1.15 1.274-3.085 1.258-1.416-.015-2.77-.566-3.257-1.558m-.001 1.963s.016 1.07.032 1.07c.456 1.48 3.918 1.4 3.918 1.385a13 13 0 0 0-.031-1.102c-1.275.032-3.084-.173-3.919-1.353"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  );
}
