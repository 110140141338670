/* eslint-disable react/no-multi-comp */
import type { ReactElement } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import type { BoxProps, StackProps, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { styledMixins } from '../theme/styledMixins';

export function EmptyStateContent({ children, ...props }: BoxProps): ReactElement {
  return (
    <Box textAlign="center" {...props}>
      {children}
    </Box>
  );
}

export function EmptyStateTitle({ children, ...props }: TypographyProps): ReactElement {
  return (
    <Typography variant="subHeadingS" {...props}>
      {children}
    </Typography>
  );
}

export function EmptyStateSubtitle({ children, ...props }: TypographyProps): ReactElement {
  return (
    <Typography variant="paragraphBodyM" {...props}>
      {children}
    </Typography>
  );
}

const StyledStack = styled(Stack)(({ theme }) => ({
  ...styledMixins.colCentered,
  '* + .MuiBox-root': {
    marginTop: theme.spacing(4),
  },
}));

export function EmptyStateRoot({ children, ...props }: StackProps): ReactElement {
  return <StyledStack {...props}>{children}</StyledStack>;
}
