import { disableRefetchRetry } from '@noah-labs/fe-shared-data-access-shared';
import { duration } from '@noah-labs/shared-util-vanilla';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { fetchDestinations } from '../data/destinations';
import type { TpNormalisedDestinations, TpSegmentDestination } from '../types';
import { groupDestinations } from '../utils/groupDestinations';

const defaultCdnUrl = 'https://cdn.segment.com';

/**
 * fetches destinatons from Segment and prevents refetching / reloading
 */
type TpUseDestinations = {
  destinations: TpSegmentDestination[];
  groupedDestinations: TpNormalisedDestinations;
};
export function useDestinations(
  writeKeys: string[],
  cdnUrl = defaultCdnUrl,
  // this query does not depend on auth or the user being loaded so can be enabled by default
  enabled = true,
): UseQueryResult<TpUseDestinations> {
  return useQuery(
    ['SegmentDestinations'],
    async () => {
      const destinations = await fetchDestinations(cdnUrl, writeKeys);
      const grouped = groupDestinations(destinations);
      return {
        destinations,
        groupedDestinations: grouped,
      };
    },
    {
      ...disableRefetchRetry,
      enabled,
      staleTime: duration.days(1),
    },
  );
}
