export enum TpCheckoutPaymentStatus {
  failure = 'failure',
  pending = 'pending',
  success = 'success',
}

export enum TpCheckoutPayoutStatus {
  failure = 'failure',
  pending = 'pending',
  success = 'success',
}

export type TpEventMapping<TEvents extends { type: string }> = {
  [K in TEvents as K['type']]: K;
};
