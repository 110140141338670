import React from 'react';
import type { TpMuiSvgIcon } from '@noah-labs/fe-shared-ui-assets';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { OnlyIconButton } from '../buttons/OnlyIconButton';

export type TpActionsOptionsRef = TpDialogToggle;

type PpActionsMenuItem = {
  Icon: TpMuiSvgIcon;
  disabled?: boolean;
  href?: string;
  label?: React.ReactNode;
  onClick?: () => void;
};

export function ActionsMenuItem({
  disabled,
  href,
  Icon,
  label,
  onClick,
}: PpActionsMenuItem): React.ReactElement {
  return (
    <OnlyIconButton
      disabled={disabled}
      href={href}
      outsideLabel={label}
      sx={{ flexGrow: 0 }}
      onClick={onClick}
    >
      <Icon sx={{ height: '22px', width: '22px' }} />
    </OnlyIconButton>
  );
}
