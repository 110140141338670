import { createFontFace } from '../../src/lib/createFontFace';

const path = 'gt-america-expanded';
const family = 'GT America Expanded';

export const normal900 = createFontFace({
  family,
  path,
  style: 'normal',
  variant: 'black',
  weight: 900,
});

export const italic900 = createFontFace({
  family,
  path,
  style: 'italic',
  variant: 'black',
  weight: 900,
});
