import React, { useEffect, useRef } from 'react';
import { mapCustomPreferences, useDestinations } from '@noah-labs/fe-shared-data-access-analytics';
import type { PpWC, TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { checkAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { logger } from '@noah-labs/shared-logger/browser';
import type { CookieConsent } from '@noah-labs/shared-schema-gql';
import { getConfig } from '../config';
import { AnalyticsContext } from './AnalyticsContext';
import { getAppTypeMiddleware } from './getAppTypeMiddleware';
import { getConsentMiddleware } from './getConsentMiddleware';
import { useAnalyticsContext } from './useAnalyticsContext';

type TpFunc = (() => void) | (() => Promise<void>);
type PpAnalyticsProvider = PpWC & {
  addSignOutSubscriber: (name: string, sub: TpFunc) => void;
  authStatus: TpAuthStatus;
  userCookies: CookieConsent | undefined | null;
};
/**
 * Loads the Segment tracking script
 * Tracking calls are buffered / queued until ajs.load is invoked
 * Only calls the load method once we know the users preferences, so that we can respect their privacy choices
 */
export function AnalyticsProvider({
  addSignOutSubscriber,
  authStatus,
  children,
  userCookies,
}: PpAnalyticsProvider): React.ReactElement {
  const ajs = useAnalyticsContext();
  const { apiHost, cdnUrl, enabled, writeKey } = getConfig();
  const { data: { destinations } = {} } = useDestinations([writeKey], cdnUrl);
  // use a useRef to ensure we only load this once
  const ajsLoaded = useRef(false);

  useEffect(() => {
    if (ajsLoaded.current) {
      logger.debug('segment already loaded');
      return;
    }
    if (!destinations) {
      logger.debug('destinations not available');
      return;
    }
    if (checkAuthStatus({ has: authStatus, needs: ['unknown'] })) {
      logger.debug('auth status unknown');
      return;
    }
    if (checkAuthStatus({ has: authStatus, needs: ['authenticated'] }) && !userCookies) {
      logger.debug('user is authed but cookie prefs not yet fetched');
      return;
    }

    logger.debug('user is a guest or full-user with cookie prefs, attempt loading segment');

    // setup some defaults in case the user has not yet set preferences
    const {
      advertising = false,
      functional = true,
      marketingAndAnalytics = false,
    } = userCookies ?? {};

    const cookiePrefs = {
      advertising,
      functional,
      marketingAndAnalytics,
    };

    // get the enabled destinations depending on the user's consent prefs
    const integrations = mapCustomPreferences({
      analyticsEnabled: enabled,
      apiHost,
      customPreferences: cookiePrefs,
      destinations,
    });

    // load the Segment analytics script, this should only be done once
    ajs.load({ cdnURL: cdnUrl, writeKey }, { initialPageview: false, integrations });

    // add the consent prefs to all the tracking calls
    const consentMiddleware = getConsentMiddleware(cookiePrefs);
    const appTypeMiddleware = getAppTypeMiddleware();
    void ajs.addSourceMiddleware(consentMiddleware);
    void ajs.addSourceMiddleware(appTypeMiddleware);

    logger.debug('loaded segment');

    // reset analytics when we user signs out
    addSignOutSubscriber('ajs', ajs.reset);
    ajsLoaded.current = true;
  }, [
    addSignOutSubscriber,
    ajs,
    apiHost,
    authStatus,
    cdnUrl,
    destinations,
    enabled,
    userCookies,
    writeKey,
  ]);

  return <AnalyticsContext.Provider value={ajs}>{children}</AnalyticsContext.Provider>;
}
