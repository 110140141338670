import type { SVGProps } from 'react';

export function SvgRadio(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="currentColor" height={13.8} rx={6.9} width={13.8} x={5.1} y={5.1} />
      <rect
        height={13.8}
        rx={6.9}
        stroke="#8A8A8A"
        strokeWidth={0.6}
        width={13.8}
        x={5.1}
        y={5.1}
      />
    </svg>
  );
}
