import { createFontFace } from '../../src/lib/createFontFace';

const path = 'gt-america-standard';
const family = 'GT America Standard';

export const normal700 = createFontFace({
  family,
  path,
  style: 'normal',
  variant: 'bold',
  weight: 700,
});

export const normal400 = createFontFace({
  family,
  path,
  style: 'normal',
  variant: 'regular',
  weight: 400,
});

export const normal500 = createFontFace({
  family,
  path,
  style: 'normal',
  variant: 'medium',
  weight: 500,
});

export const italic400 = createFontFace({
  family,
  path,
  style: 'italic',
  variant: 'regular-italic',
  weight: 400,
});

export const normal200 = createFontFace({
  family,
  path,
  style: 'normal',
  variant: 'thin',
  weight: 200,
});
