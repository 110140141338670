import type { FeatureFlag } from '@noah-labs/shared-schema-gql';
import { Feature, FeatureFlagReason, KycApprovalStatus } from '@noah-labs/shared-schema-gql';
import { isArrayOfLength } from '@noah-labs/shared-util-vanilla';

type PpGetEnabledWithKYCFeature = {
  feature: Feature;
  fm: Map<Feature, FeatureFlag>;
  kycStatus: KycApprovalStatus | null | undefined;
};
type TpEnabledWithKYCFeature = Omit<FeatureFlag, 'Feature'>;
export function getEnabledWithKYCFeature({
  feature,
  fm,
  kycStatus,
}: PpGetEnabledWithKYCFeature): TpEnabledWithKYCFeature {
  const featureFlag = fm.get(feature);
  const kycFeatureFlag = fm.get(Feature.Kyc);
  const businessFeatureFlag = fm.get(Feature.Business);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { Enabled: e, Feature: f, ...rest } = featureFlag || {};
  const disabled = { ...rest, Enabled: false };
  const enabledOrNeedsKyc = { ...rest, Enabled: true, Reason: null };

  // in case of a business user, we grant full access
  if (businessFeatureFlag?.Enabled) {
    return { ...rest, Enabled: true, Reason: null };
  }

  // if the 'parent' feature is already enabled, then this feature is enabled
  if (featureFlag?.Enabled) {
    return { ...rest, Enabled: true };
  }

  // if KYC is disabled for the user, then this feature is disabled
  if (!kycFeatureFlag?.Enabled) {
    return disabled;
  }

  // if the feature is blocked by anything other than only KYC, then passing KYC won't help
  if (
    !isArrayOfLength(featureFlag?.Reason, 1) ||
    featureFlag?.Reason?.[0] !== FeatureFlagReason.Kyc
  ) {
    return disabled;
  }

  // if kycStatus is defined, it means user has attempted to KYC at least once
  // if they are not yet approved, we should still block
  if (kycStatus && kycStatus !== KycApprovalStatus.Approved) {
    return disabled;
  }

  // At this point we know
  // - the ONLY reason the feature is disabled is because because of KYC
  // - user has not yet attemped KYC nor has a pending / rejected kyc approval status
  // - or is already approved (which is a noop really because that would likely be handled in our first if statement)
  // - if the user passes KYC it will enable this feature.
  // In the case of CkoBuy / Sell, for example, we allow access to the feature but 'executing' the feature
  // would still require the user to KYC and the app should redirect appropriately at the appropriate step
  return enabledOrNeedsKyc;
}
