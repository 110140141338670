import { Controller, useFormContext } from 'react-hook-form';
import type { PpInputFieldAtom } from './atoms/InputFieldAtom';
import { InputFieldAtom } from './atoms/InputFieldAtom';

export type PpInputField = PpInputFieldAtom;
export function InputField({ name, ...rest }: PpInputField): React.ReactElement {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }): React.ReactElement => (
        <InputFieldAtom {...field} errorMessage={fieldState.error?.message} {...rest} name={name} />
      )}
    />
  );
}
