import type { FullName } from '@noah-labs/shared-schema-gql';
import { isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';

export function getUserFullDisplayName(fullName: FullName | null | undefined): string | undefined {
  if (isUndefinedOrNull(fullName)) {
    return undefined;
  }

  const { FirstName, LastName, MiddleName } = fullName;

  return [FirstName, MiddleName, LastName].filter(Boolean).join(' ');
}
