import { useCallback, useState } from 'react';
import type { TpSkeletonText } from '@noah-labs/fe-shared-ui-shared';
import * as clipboard from 'clipboard-polyfill/text';
import { usePushAlert } from '../alerts/usePushAlert';

type TpUseClickToCopy = {
  copied: boolean;
  copyHandler: () => Promise<void>;
};

type TpUseClickToCopyProps = {
  onCopy?: () => void;
  text: TpSkeletonText;
};

export function useClickToCopy({ onCopy, text }: TpUseClickToCopyProps): TpUseClickToCopy {
  const [copied, setCopied] = useState(false);
  const pushAlert = usePushAlert();

  const copyHandler = useCallback(async (): Promise<void> => {
    if (!text) {
      return;
    }
    try {
      await clipboard.writeText(`${text}`);
      setCopied(true);
    } catch (error) {
      setCopied(false);
      pushAlert({
        autoHideDuration: 3000,
        key: `copyError`,
        message: 'There was an error copying to your clipboard',
        severity: 'error',
      });
    }
    if (onCopy) {
      void onCopy();
    }
  }, [pushAlert, text, onCopy]);

  return { copied, copyHandler };
}
