import { isUndefinedOrNull } from './utils';

export function parseTruthyEnv(envvar: string | number | boolean | undefined | null): boolean {
  const truthyValues = ['true', '1'];

  if (!isUndefinedOrNull(envvar) && truthyValues.includes(envvar.toString().toLowerCase())) {
    return true;
  }
  return false;
}

export const partialSnapshots = parseTruthyEnv(process.env.STORYBOOK_CHROMATIC_PARTIAL);

function throwMissingEnvVarError(envvar: string): never {
  throw new Error(`Missing environment variable: ${envvar}`);
}

export function mustGetProcessEnv(envvar: string): string {
  return process.env[envvar] ?? throwMissingEnvVarError(envvar);
}
