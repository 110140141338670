import type { SVGProps } from 'react';

export function SvgSearch(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 25"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m20 20.107-3.778-3.784m2.094-5.058a7.158 7.158 0 1 1-14.316 0 7.158 7.158 0 0 1 14.316 0z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={2.5}
      />
    </svg>
  );
}
