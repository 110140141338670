import { useCallback, useState } from 'react';
import type { CSSObject, SxProps, Theme } from '@mui/material';

type TpUseLayoutBalancer = {
  endIconsRef: (el: HTMLDivElement | null) => void;
  iconStyles: {
    end?: SxProps<Theme>;
    start?: SxProps<Theme>;
  };
  startIconsRef: (el: HTMLDivElement | null) => void;
};

type PpUseLayoutBalancer = {
  disableBalancing?: boolean;
};
export function useLayoutBalancer({
  disableBalancing = false,
}: PpUseLayoutBalancer = {}): TpUseLayoutBalancer {
  const [startIconsWidth, setStartIconsWidth] = useState(0);
  const [endIconsWidth, setEndIconsWidth] = useState(0);

  const startIconsRef = useCallback((el: HTMLDivElement | null) => {
    if (el === null) {
      return;
    }
    setStartIconsWidth(el.offsetWidth);
  }, []);

  const endIconsRef = useCallback((el: HTMLDivElement | null) => {
    if (el === null) {
      return;
    }
    setEndIconsWidth(el.offsetWidth);
  }, []);

  const iconDiff = Math.abs(startIconsWidth - endIconsWidth);
  const iconPlaceholder = `${iconDiff}px`;

  let startPlaceholder: CSSObject['padding'] = 0;
  let endPlaceholder: CSSObject['padding'] = 0;

  if (!disableBalancing) {
    if (endIconsWidth > startIconsWidth) {
      startPlaceholder = iconPlaceholder;
    } else {
      endPlaceholder = iconPlaceholder;
    }
  }

  const iconStyles = {
    end: {
      paddingLeft: endPlaceholder,
    },
    start: {
      paddingRight: startPlaceholder,
    },
  };

  return {
    endIconsRef,
    iconStyles,
    startIconsRef,
  };
}
