import { getNetworksAndCurrenciesFromConfig } from '@noah-labs/fe-shared-data-access-wallet';
import { AccountType } from '@noah-labs/shared-schema-gql';

export function getRouteSegments(): { accountType: string; currencyCode: string; network: string } {
  const [networksFromConfig, currenciesFromConfig] = getNetworksAndCurrenciesFromConfig();
  const currencyCodes = currenciesFromConfig.join('|').toLowerCase();
  const networks = networksFromConfig.join('|').toLowerCase();
  const accountTypes = Object.keys(AccountType).join('|').toLowerCase();

  const currencyCode = `:currencyCode(${currencyCodes})`;
  const network = `:network(${networks})`;
  const accountType = `:accountType(${accountTypes})`;

  return {
    accountType,
    currencyCode,
    network,
  };
}
