type TpOryUrls = {
  returnTo: string;
  signinUrl: string;
};
type PpGetOryUrls = {
  appUrl: string;
  oryCustomUrl: string;
  returnPath: string;
  searchParams?: Record<string, string>;
};
export function getOryUrls({
  appUrl,
  oryCustomUrl,
  returnPath,
  searchParams,
}: PpGetOryUrls): TpOryUrls {
  /**
   * Add the searchParams (e.g. including referralCode) to the return_to url so we can parse it when the user returns,
   */
  const returnToUrl = new URL(returnPath, appUrl);
  const sp = new URLSearchParams(searchParams);
  returnToUrl.search = sp.toString();
  const returnTo = returnToUrl.toString();

  /**
   * Create the signinUrl with the return_to parameter
   */
  const signinUrl = new URL('ui/login', oryCustomUrl);
  signinUrl.searchParams.append('return_to', returnTo);

  return {
    returnTo,
    signinUrl: signinUrl.toString(),
  };
}
