import type { SVGProps } from 'react';

export function SvgCopy(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 25"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M4.986 4.46a.633.633 0 0 0-.633.634v8.145c0 .35.284.633.633.633h.641a1.177 1.177 0 0 1 0 2.353h-.64A2.986 2.986 0 0 1 2 13.24V5.094a2.986 2.986 0 0 1 2.986-2.987h8.145a2.986 2.986 0 0 1 2.987 2.987v.64a1.176 1.176 0 1 1-2.353 0v-.64a.633.633 0 0 0-.634-.634zm2.896 7.06a3.53 3.53 0 0 1 3.53-3.53h7.059A3.53 3.53 0 0 1 22 11.52v7.058a3.53 3.53 0 0 1-3.53 3.53h-7.058a3.53 3.53 0 0 1-3.53-3.53v-7.059Zm3.53-1.177c-.65 0-1.177.526-1.177 1.176v7.059c0 .65.527 1.177 1.177 1.177h7.059c.65 0 1.176-.527 1.176-1.177v-7.059c0-.65-.527-1.176-1.176-1.176z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
