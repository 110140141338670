import {
  GenericPINError,
  IncorrectPinError,
  LockedPinError,
  signPayloadWithNcw,
} from '@noah-labs/shared-cryptography';
import type { HDWallet } from '@noah-labs/shared-cryptography';
import { HttpStatusCode, isAxiosError } from 'axios';
import type { TpSecretDocumentData } from '../types';

const SIGNATURE_VERSION = 'v1';

/**
 * Signs the given payload using the given wallet (NCW)
 * @param payload payload to sign (must be an array of strings in the correct order)
 * @param wallet non custody wallet
 * @returns signature request
 */
export async function signPayload({
  payload,
  timestamp,
  wallet,
}: {
  payload: string[];
  timestamp: string;
  wallet: HDWallet;
}): Promise<string> {
  const signedPayload = await signPayloadWithNcw({
    payload: payload.join('/'),
    wallet,
  });
  return [SIGNATURE_VERSION, timestamp, signedPayload].join('/');
}

/**
 * Converts HTTP errors from the decrypt endpoint into the appropriate error type.
 * Bubble up the error if not a decrypt error.
 * @param err error
 * @param sd secret document data
 * @throws IncorrectPinError - the pin is incorrect and there are more attempts left
 * @throws LockedPinError - the pin is locked, meaning no more decrypt attempts are left
 * @throws GenericPINError - unrecognized decrypt error
 */
export function handleDecryptError(err: unknown, sd?: TpSecretDocumentData): never {
  if (!isAxiosError(err) || !err.response) {
    throw err;
  }

  const { status } = err.response;

  if (status === HttpStatusCode.Forbidden && sd) {
    throw new IncorrectPinError(sd.DecryptAttempts - 1);
  }

  if (status === HttpStatusCode.Locked) {
    throw new LockedPinError();
  }

  throw new GenericPINError();
}
