import { css } from '@emotion/react';
import type { ButtonProps } from '@mui/material';
import { Button, ButtonBase, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getInteractiveProps } from '../links/getInteractiveProps';

export type PpOnlyIconButton = Pick<
  ButtonProps,
  'children' | 'color' | 'disabled' | 'href' | 'onClick' | 'size' | 'sx' | 'variant'
> & {
  circular?: boolean;
  outsideLabel?: React.ReactNode;
};

export function OnlyIconButton({
  children,
  circular = true,
  color = 'primary',
  disabled,
  href,
  onClick,
  outsideLabel,
  size = 'mediumIcon',
  sx,
  variant = 'contained',
}: PpOnlyIconButton): React.ReactElement {
  const theme = useTheme();
  const styles = {
    button: css`
      /* Set a max width so that the label doesn't affect the spacing */
      max-width: ${theme.spacing(6)};
      overflow: visible;
    `,
    innerButton: css`
      border-radius: ${circular ? '50%' : theme.borderRadius.xs};
    `,
  };

  const interactiveProps = getInteractiveProps({ href, onClick });

  return (
    <ButtonBase
      {...interactiveProps}
      css={styles.button}
      disabled={disabled}
      href={href}
      sx={sx}
      onClick={onClick}
    >
      <Stack alignItems="center" spacing={1}>
        <Button
          color={color}
          component="span"
          css={styles.innerButton}
          disabled={disabled}
          role={undefined}
          size={size}
          tabIndex={undefined}
          variant={variant}
        >
          {children}
        </Button>
        {outsideLabel && (
          <Typography noWrap component="span" variant="paragraphBodyS">
            {outsideLabel}
          </Typography>
        )}
      </Stack>
    </ButtonBase>
  );
}
