import type { ReactElement } from 'react';
import { lazy, memo, Suspense } from 'react';
import { CircularProgress } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { styledMixins } from '../theme/styledMixins';

const StyledQRCode = lazy(() => import('./StyledQRCode'));

const StyledContainer = styled('div')<Pick<PpQrCodeWithImage, 'wrapperSize'>>(
  ({ theme, wrapperSize }) => ({
    alignItems: 'center',
    borderRadius: theme.borderRadius.sm,
    'div, svg': {
      ...styledMixins.fillParent,
    },
    justifyContent: 'center',
    padding: theme.spacing(1),
    position: 'relative',
    ...styledMixins.fillParent,
    backgroundColor: theme.palette.grayscale.white,
    display: 'flex',
    height: wrapperSize,
    width: wrapperSize,
  }),
);

export type PpQrCodeWithImage = {
  data?: string;
  height: number;
  imageUrl: string;
  width: number;
  wrapperSize?: number;
};

/**
 * The QR Code will always fill the parent container.
 * @param {number} height - Only affects the quality of the QR Code, not the height itself.
 * @param {number} width - Only affects the quality of the QR Code, not the width itself.
 */
function QRCodeWithImageComponent({
  data,
  height,
  imageUrl,
  width,
  wrapperSize,
}: PpQrCodeWithImage): ReactElement {
  const theme = useTheme();

  return (
    <StyledContainer wrapperSize={wrapperSize}>
      <Suspense fallback={null}>
        <StyledQRCode
          backgroundOptions={{ color: theme.palette.grayscale.white }}
          cornersDotOptions={{ color: theme.palette.common.black, type: 'dot' }}
          cornersSquareOptions={{ color: theme.palette.common.black, type: 'extra-rounded' }}
          data={data}
          dotsOptions={{ color: theme.palette.common.black, type: 'dots' }}
          height={height}
          image={imageUrl}
          imageOptions={{
            hideBackgroundDots: true,
            imageSize: 0.5,
            margin: 15,
          }}
          type="svg"
          width={width}
        />
      </Suspense>

      {!data && <CircularProgress size={16} sx={{ position: 'absolute' }} />}
    </StyledContainer>
  );
}

export const QRCodeWithImage = memo(QRCodeWithImageComponent);
