module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#FF6600","display":"standalone","icon":"./src/images/app-icon.svg","icon_options":{"purpose":"maskable any"},"name":"NOAH","short_name":"NOAH","start_url":"/","theme_color":"#ffffff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d685581687c0abd7cc8a4ae4964bb4d3"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/"],"workboxConfig":{"runtimeCaching":[{"handler":"CacheFirst","urlPattern":{}},{"handler":"NetworkFirst","urlPattern":{}},{"handler":"StaleWhileRevalidate","urlPattern":{}}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
