import LibraryAddCheck from '@mui/icons-material/LibraryAddCheck';
import { CopyIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CopyIcon';

type PpDynamicCopyIcon = {
  copied: boolean;
};

export function DynamicCopyIcon({ copied }: PpDynamicCopyIcon): React.ReactElement {
  return copied ? <LibraryAddCheck sx={{ color: 'success.main' }} /> : <CopyIcon />;
}
