import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export function useOnRouteChange(onRouteChange: () => void): void {
  const history = useHistory();

  useEffect(() => {
    // Listen for route changes
    const unlisten = history.listen(() => {
      onRouteChange();
    });

    // Cleanup the listener when the component unmounts
    return unlisten;
  }, [history, onRouteChange]);
}
