/* eslint-disable react/jsx-no-useless-fragment */
import type { ReactElement } from 'react';
import React from 'react';
import { truncateString } from '@noah-labs/shared-util-vanilla';

export type PpTruncatedText = {
  prefix?: number;
  suffix?: number;
  text: string;
};

export function TruncatedText({ prefix, suffix, text }: PpTruncatedText): ReactElement {
  const truncatedText = truncateString(text, { prefix, suffix });

  return <React.Fragment>{truncatedText}</React.Fragment>;
}
