import { Stack } from '@mui/material';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

export function ActionsMenu({ children }: PpWC): React.ReactElement {
  return (
    <Stack direction="row" spacing={4} sx={{ alignItems: 'flex-start', justifyContent: 'center' }}>
      {children}
    </Stack>
  );
}
