import type { SVGProps } from 'react';

export function SvgArrowUpRightCircleThick(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 12c0 6.628-5.373 12-12 12S0 18.628 0 12C0 5.373 5.373.001 12 .001s12 5.372 12 12Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M12 21.6a9.6 9.6 0 1 0-9.6-9.599c0 5.3 4.298 9.599 9.6 9.599m0 2.4c6.627 0 12-5.372 12-12C24 5.373 18.627.001 12 .001s-12 5.372-12 12C0 18.627 5.373 24 12 24"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M10.124 8.001a1.15 1.15 0 0 0 0 2.302h1.947l-3.734 3.734a1.15 1.15 0 1 0 1.627 1.627l3.734-3.733v1.866a1.15 1.15 0 1 0 2.301 0L16 8.002h-5.876Z"
        fill="#fff"
      />
    </svg>
  );
}
