import { isValidCurrency, isValidNetwork } from '@noah-labs/shared-currencies';
import type { CurrencyCode, Network } from '@noah-labs/shared-schema-gql';
import { getConfig } from '../config';

export function getNetworksAndCurrenciesFromConfig(): [Network[], CurrencyCode[]] {
  const { currencies } = getConfig();
  const networkCurrencyKeys = Object.keys(currencies.configurations);
  const currencySet = new Set<CurrencyCode>();
  const networkSet = new Set<Network>();

  networkCurrencyKeys.forEach((key) => {
    const [network, currencyCode] = key.split('/');
    if (isValidNetwork(network)) {
      networkSet.add(network);
    }
    if (isValidCurrency(currencyCode)) {
      currencySet.add(currencyCode);
    }
  });

  return [Array.from(networkSet), Array.from(currencySet)];
}
