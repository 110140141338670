import type { SVGProps } from 'react';

export function SvgCheckedCheckbox(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      data-name="Layer 1"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="currentColor" height={24} rx={8.07} ry={8.07} width={24} />
      <path
        d="m6.75 12.25 3.5 3.5 7-7.5"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.5}
      />
    </svg>
  );
}
