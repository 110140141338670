import { css } from '@emotion/react';
import type { FormControlLabelProps } from '@mui/material';
import { FormControlLabel, Radio } from '@mui/material';
import { useRadioGroup } from '@mui/material/RadioGroup';
import { useTheme } from '@mui/material/styles';
import { CheckedRadioIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CheckedRadioIcon';
import { RadioIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/RadioIcon';
import {
  ListItemContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from '../lists/ListItem';

export type PpRadioButton = Pick<FormControlLabelProps, 'checked' | 'label' | 'labelPlacement'> & {
  Avatar?: React.ReactElement | null;
  disablePadding?: boolean;
  hideValue?: boolean;
  value: string;
};

export function RadioButton({
  Avatar,
  checked = false,
  disablePadding = false,
  hideValue,
  label,
  labelPlacement,
  value,
}: PpRadioButton): React.ReactElement {
  const theme = useTheme();
  const radioGroup = useRadioGroup();
  let isChecked = checked;
  if (radioGroup) {
    isChecked = radioGroup.value === value;
  }

  const styles = {
    checkedIcon: {
      color: theme.palette.primaryBrand.main,
    },
    icon: {
      fontSize: '2rem',
    },
    labelPadding: css`
      padding: ${theme.spacing(1.5, 2)};
    `,
  };

  return (
    <FormControlLabel
      disableTypography
      aria-checked={isChecked}
      checked={isChecked}
      control={
        <Radio
          checkedIcon={<CheckedRadioIcon css={[styles.icon, styles.checkedIcon]} />}
          icon={<RadioIcon sx={styles.icon} />}
        />
      }
      css={!disablePadding && styles.labelPadding}
      data-qa={value}
      label={
        <ListItemContent padding={0}>
          <ListItemIcon>{Avatar}</ListItemIcon>
          <ListItemStartContent>
            <ListItemPrimaryText>{label}</ListItemPrimaryText>
            {!hideValue && <ListItemSecondaryText>{value}</ListItemSecondaryText>}
          </ListItemStartContent>
        </ListItemContent>
      }
      labelPlacement={labelPlacement}
      value={value}
    />
  );
}
