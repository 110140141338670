import { useContext } from 'react';
import type { CtxRouter } from './RouterContext';
import { RouterContext } from './RouterContext';

export function useRouter(): CtxRouter {
  const context = useContext(RouterContext);

  if (context === undefined) {
    throw new Error('useRouter must be used within a RouterProvider');
  }

  return context;
}
