import type { SVGProps } from 'react';

export function SvgClose(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 25"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m13.06 12.107 6.22-6.22a.75.75 0 0 0-1.06-1.06L12 11.047l-6.22-6.22a.75.75 0 0 0-1.06 1.06l6.22 6.22-6.22 6.22a.75.75 0 1 0 1.06 1.06L12 13.169l6.22 6.22a.75.75 0 0 0 1.06-1.06l-6.22-6.22Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.8}
      />
    </svg>
  );
}
