import { Container, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

const StyledHeader = styled('header')(({ theme }) => ({
  backgroundColor: theme.palette.grayscale.offWhite,
  borderRadius: `0 0 ${theme.borderRadius.lg} ${theme.borderRadius.lg}`,
  boxShadow: theme.shadows[2],
  // we use padding to ensure any background colours are 'full bleed'
  padding: theme.spacing(3, 0, 2),

  [theme.breakpoints.up('lg')]: {
    backgroundColor: 'tranparent',
    boxShadow: 'none',
    paddingBottom: 0,
  },
}));

type PpSceneHeaderCard = PpWC & {
  spacing?: number;
};
export function SceneHeaderCard({ children, spacing = 4 }: PpSceneHeaderCard): React.ReactElement {
  return (
    <StyledHeader>
      <Container component={Stack} maxWidth="sm" spacing={spacing}>
        {children}
      </Container>
    </StyledHeader>
  );
}
