import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

type PpMuiSvgIcon = SvgIconProps & {
  svg: TpReactSvg | null | undefined;
};

/**
 * MuiSvgIcon is a wrapper around Mui's SvgIcon that we can use with the icon fields from TpCryptoCurrencyUI, TpFiatCurrencyUI for correct typings.
 * This is because the original type is a string but because we use SVGR webpack loader, it will be a React component
 */
export function MuiSvgIcon({ svg, ...rest }: PpMuiSvgIcon): React.ReactElement {
  return <SvgIcon component={svg} {...rest} />;
}
