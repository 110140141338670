import { css } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { GTAMExpanded, GTAMExtended, GTAMStandard } from '@noah-labs/fe-feature-assets';
import { coreThemeProps, cssMixins } from '@noah-labs/fe-shared-ui-components';
import { getWebComponents } from './components';

const lightThemeBase = createTheme(
  deepmerge(coreThemeProps, {
    themeName: 'Web',
  }),
);

export const lightTheme = createTheme(lightThemeBase, {
  components: getWebComponents(lightThemeBase),
});

export const globalStyles = css`
  ${cssMixins.global};
  ${GTAMStandard};
  ${GTAMExpanded};
  ${GTAMExtended};
`;
