import { useCallback, useEffect, useRef, useState } from 'react';
import { logger } from '@noah-labs/shared-logger/browser';
import { useSessionStorage } from 'react-use';

export type TpStateMachine<T> = {
  resetState: () => void;
  setStore: (ev?: Event) => void;
  state: T;
  updateState: (newState: Partial<T>) => void;
};

type PpUseStateMachine<T> = {
  emptyState: T;
  initialState?: T;
  name: string;
  onReset?: () => void;
};
export function useStateMachine<T = Record<string, unknown>>({
  emptyState,
  initialState,
  name,
  onReset,
}: PpUseStateMachine<T>): TpStateMachine<T> {
  /**
   * set a ref with the empty state for resetting later
   */
  const emptyStateRef = useRef(emptyState);

  /**
   * check if there is some persistedState - in the case of a page refresh for example
   */
  const [persistedState, setPersistedState] = useSessionStorage<T>(name);

  /**
   * setup state
   */
  const [state, setState] = useState<T>(persistedState || initialState || emptyState);

  /**
   * updateState accepts a partial set of state values and merges them in to the current state
   */
  const updateState = useCallback((newState: Partial<T>): void => {
    setState((prevState) => ({ ...prevState, ...newState }));
  }, []);

  /**
   * resetState should be called by the last step in the flow to reset state, normally in response to a navigation event
   */
  const resetState = useCallback((): void => {
    setState(emptyStateRef.current);
    if (typeof onReset === 'function') {
      onReset();
    }
  }, [onReset]);

  /**
   * handle saving or resetting our persistedState
   */
  const handledTerminatingEvent = useRef(false);
  const setStore = useCallback(
    (ev?: Event) => {
      logger.debug('setStore', state);
      logger.debug('event', ev);
      if (handledTerminatingEvent.current) {
        return;
      }

      switch (ev?.type) {
        case 'visibilitychange':
          if (document.visibilityState !== 'hidden') {
            break;
          }
          setPersistedState(state);
          handledTerminatingEvent.current = true;
          break;

        default:
          setPersistedState(state);
          handledTerminatingEvent.current = true;
          break;
      }
    },
    [setPersistedState, state],
  );

  useEffect(() => {
    /**
     * save state to session storage if page is being navigated away from
     * see: https://tech.trivago.com/post/2020-11-17-exploringthepagevisibilityapifordetectin.html
     * handles more use cases and browsers than just beforeUnload
     */
    document.addEventListener('visibilitychange', setStore);
    window.addEventListener('pagehide', setStore);
    window.addEventListener('beforeunload', setStore);
    window.addEventListener('unload', setStore);

    /**
     * when the component unmounts, it means the user has finished the flow, discard the current state and persistedState
     */
    return (): void => {
      document.removeEventListener('visibilitychange', setStore);
      window.removeEventListener('pagehide', setStore);
      window.removeEventListener('beforeunload', setStore);
      window.removeEventListener('unload', setStore);
      window.sessionStorage.removeItem(name);
    };
  }, [name, setStore]);

  return { resetState, setStore, state, updateState };
}
