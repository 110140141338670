/* eslint-disable react/no-multi-comp */
import { forwardRef } from 'react';
import { Dialog, Slide } from '@mui/material';
import type { CSSObject, DialogProps } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';

type PpLowDialogProps = DialogProps & {
  backgroundColor?: CSSObject['backgroundColor'];
  dataQa?: string;
  tallScreen?: boolean;
};

const Transition = forwardRef<
  unknown,
  TransitionProps & {
    children: React.ReactElement;
  }
>((props, ref) => <Slide ref={ref} direction="up" {...props} />);

Transition.displayName = Transition.name;

export function LowDialog({
  backgroundColor,
  dataQa,
  fullScreen,
  tallScreen,
  ...rest
}: PpLowDialogProps): React.ReactElement {
  return (
    <Dialog
      fullWidth
      data-qa={dataQa && `${dataQa}-dialog`}
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          backgroundColor,
          /**
           * already has this style applied by mui - max-height: calc(100% - 64px);
           */
          height: tallScreen ? '100%' : undefined,
        },
      }}
      TransitionComponent={Transition}
      {...rest}
    />
  );
}
