import type { SVGProps } from 'react';

export function SvgNoahSquare(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      clipRule="evenodd"
      fillRule="evenodd"
      height="1em"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
    >
      <path
        d="M2.947 0A2.96 2.96 0 0 0 0 2.948v18.105A2.947 2.947 0 0 0 2.947 24h18.106A2.96 2.96 0 0 0 24 21.053V2.948A2.95 2.95 0 0 0 21.053 0zm5.785 10.888v7.092H4.825V6.022H9.29l4.9 5.574c.32.37.741.96 1.01 1.482h.085V6.022h3.89V17.98h-4.21l-5.12-5.576c-.336-.37-.859-1.095-1.027-1.516z"
        fill="currentColor"
      />
    </svg>
  );
}
