//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgSell } from '../icons/Sell';

export function SellIcon(props: Omit<SvgIconProps, 'component' | 'children'>): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgSell} data-qa="SellIcon">
      <svg />
    </SvgIcon>
  );
}
