/* eslint-disable react/no-multi-comp */
import type { TextFieldProps } from '@mui/material';
import { Box, DialogContent, InputAdornment, TextField, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { SearchIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/SearchIcon';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

type ACTextFieldProps = TextFieldProps & {
  dataQa?: string;
  hide: boolean;
  rootProps: React.HTMLAttributes<HTMLDivElement>;
};

export function ACTextField({
  dataQa,
  hide,
  rootProps,
  slotProps,
  ...rest
}: ACTextFieldProps): React.ReactElement {
  return (
    <Box sx={hide ? visuallyHidden : undefined} {...rootProps}>
      <TextField
        {...rest}
        autoFocus
        fullWidth
        placeholder="Select or search"
        slotProps={{
          htmlInput: {
            ...slotProps?.htmlInput,
            'data-qa': dataQa,
          },
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          },
        }}
      />
    </Box>
  );
}

export function ACContent({ children }: PpWC): React.ReactElement {
  return <DialogContent sx={{ p: 0 }}>{children}</DialogContent>;
}

type PpACNoOptions = {
  children?: React.ReactNode;
};
export function ACNoOptions({ children }: PpACNoOptions): React.ReactElement {
  return (
    <Typography sx={{ p: 2 }}>
      {children || 'No options match your search, please try again.'}
    </Typography>
  );
}
