const oneSecond = 1000;
const oneMinute = 60 * oneSecond;
const oneHour = 60 * oneMinute;
const oneDay = 24 * oneHour;

type TpDurationFn = {
  (amount: number): number;
};

type TpDurations = {
  days: TpDurationFn;
  hours: TpDurationFn;
  minutes: TpDurationFn;
  seconds: TpDurationFn;
};

export const duration: TpDurations = {
  days: (days) => days * oneDay,
  hours: (hours) => hours * oneHour,
  minutes: (mins) => mins * oneMinute,
  seconds: (seconds) => seconds * oneSecond,
};

export function timeElapsed(startTime: number): string {
  const endTime = new Date().getTime();
  const elapsedTime = endTime - startTime;

  const minutes = Math.floor(elapsedTime / 60000); // 1 minute = 60000 milliseconds
  const seconds = Math.floor((elapsedTime % 60000) / 1000); // 1 second = 1000 milliseconds

  if (minutes === 0) {
    return `${seconds}s`;
  }
  return `${minutes}m${seconds}s`;
}
