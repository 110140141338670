import type { SVGProps } from 'react';

export function SvgChevronRight(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 25"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m9 5.107 6.75 6.75L9 18.607"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      />
    </svg>
  );
}
