import type { ReactElement } from 'react';
import { lazy, Suspense, useCallback } from 'react';
import { css } from '@emotion/react';
import { logger } from '@noah-labs/shared-logger/browser';
import type { Result } from '@zxing/library-qr';
import { LoadingPage } from '../utility/LoadingPage';
import { isCameraUnvailable } from './isCameraAvailable';

type TpRes = Result | undefined | null;
type TpErr = Error | undefined | null;

const QRReader = lazy(() => import('./react-qr-reader'));

export type PpQRScanner = {
  appHeaderHeight?: number | undefined;
  onSuccess: (code: string) => void;
  onUnavailable: (reason: string) => void;
};

export function QRScanner({
  appHeaderHeight,
  onSuccess,
  onUnavailable,
}: PpQRScanner): ReactElement {
  const styles = {
    container: css`
      position: relative;
      width: 100%;
      overflow: hidden;
    `,
    gradientDownToTop: css`
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 10%;
      background: linear-gradient(to top, #000000cc, #00000000);
    `,
    gradientTopToDown: css`
      position: absolute;
      top: 0;
      width: 100%;
      height: 10%;
      background: linear-gradient(to bottom, #000000cc, #00000000);
    `,
    mask: css`
      position: absolute;
      border: 1px solid #ffffff;
      border-radius: 10px;
      box-shadow: 0px 0px 0px 2000px rgba(0, 0, 0, 0.6);

      @media (orientation: landscape) {
        top: 10%;
        left: 30%;
        width: 40%;
        padding-bottom: 40%;
      }

      @media (orientation: portrait) {
        top: 10%;
        left: 10%;
        width: 80%;
        padding-bottom: 80%;
      }
    `,
    qrContainer: {
      height: `calc(100vh - ${appHeaderHeight !== undefined ? appHeaderHeight : 0}px)`,
      width: '100%',
    },
    video: {
      objectFit: 'cover',
    },
    videoContainer: {
      height: '100%',
      paddingTop: 0,
    },
  };

  const onResult = useCallback(
    (result: TpRes, error: TpErr): void => {
      if (result) {
        onSuccess(result.getText());
        return;
      }

      if (error) {
        if (error instanceof DOMException && isCameraUnvailable(error)) {
          onUnavailable(error.name);
        }
        logger.debug(error);
        // otherwise ignore the error, it normally means it just didn't scan correctly yet
      }
    },
    [onSuccess, onUnavailable],
  );

  return (
    <Suspense fallback={<LoadingPage sx={{ minHeight: 200 }} />}>
      <div css={styles.container} data-qa="qr-scanner">
        <QRReader
          constraints={{ facingMode: 'environment' }}
          containerStyle={styles.qrContainer}
          videoContainerStyle={styles.videoContainer}
          videoStyle={styles.video}
          onResult={onResult}
        />
        <div css={styles.mask} />
        <div css={styles.gradientTopToDown} />
        <div css={styles.gradientDownToTop} />
      </div>
    </Suspense>
  );
}
