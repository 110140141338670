import type { HTMLAttributeAnchorTarget } from 'react';
import type { LoadingButtonProps } from '@mui/lab';
import { LoadingButton } from '@mui/lab';

export type PpPrimaryButton = LoadingButtonProps & {
  target?: HTMLAttributeAnchorTarget | undefined;
};

export function PrimaryButton(props: PpPrimaryButton): React.ReactElement {
  return (
    <LoadingButton fullWidth color="primaryDark" size="large" variant="contained" {...props} />
  );
}
