import type { ReactNode } from 'react';
import { css } from '@emotion/react';
import { BottomNavigationAction } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import type { TpMuiSvgIcon } from '@noah-labs/fe-shared-ui-assets';
import { NavLink } from 'react-router-dom';

export type PpNavBarItem = {
  Icon: TpMuiSvgIcon;
  label: ReactNode;
  to: string;
};

export function NavBarItem({ Icon, label, to }: PpNavBarItem): React.ReactElement {
  const theme = useTheme();

  const styles = {
    item: css`
      flex: 0 0 auto;
      min-width: 90px;
      color: ${theme.palette.grayscale[400]};
      padding: ${theme.spacing(2.25)};

      &.active {
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(2.25)};
      }
    `,
  };

  return (
    <BottomNavigationAction
      component={NavLink}
      css={styles.item}
      icon={<Icon />}
      label={label}
      sx={{ '.MuiBottomNavigationAction-label': visuallyHidden }}
      to={to}
    />
  );
}
