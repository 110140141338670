/* eslint-disable react/no-array-index-key */
import { Avatar, Skeleton } from '@mui/material';
import { List } from './List';
import {
  ListItem,
  ListItemCard,
  ListItemContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from './ListItem';

type PpSkeletonQuestList = {
  rows?: number;
};

export function SkeletonQuestList({ rows = 2 }: PpSkeletonQuestList): React.ReactElement {
  return (
    <List disablePadding spacing={1.5}>
      {new Array(rows).fill(null).map((_, index) => (
        <ListItem key={index} data-qa="skeleton">
          <ListItemCard>
            <ListItemContent>
              <ListItemIcon>
                <Avatar variant="square">
                  <Skeleton variant="rectangular" />
                </Avatar>
              </ListItemIcon>
              <ListItemStartContent>
                <ListItemPrimaryText>
                  <Skeleton sx={{ px: '6rem' }} variant="text" />
                </ListItemPrimaryText>
                <ListItemSecondaryText>
                  <Skeleton variant="text" />
                </ListItemSecondaryText>
              </ListItemStartContent>
            </ListItemContent>
          </ListItemCard>
        </ListItem>
      ))}
    </List>
  );
}
