import type { SVGProps } from 'react';

export function SvgSwitchArrows(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m10.163 7.51.97.971 3.851-3.843v8.382h1.378V4.638l3.843 3.85.978-.978L15.673 2zm3.672 8.98-.971-.971-3.85 3.843V10.98H7.636v8.382l-3.843-3.85-.978.978L8.325 22z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.5}
      />
    </svg>
  );
}
