import type { SVGProps } from 'react';

export function SvgPlusCircleThick(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 12c0 6.628-5.373 12-12 12S0 18.628 0 12C0 5.373 5.373.001 12 .001s12 5.372 12 12Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M12 21.6a9.6 9.6 0 1 0-9.6-9.599c0 5.3 4.298 9.599 9.6 9.599m0 2.4c6.627 0 12-5.372 12-12C24 5.373 18.627.001 12 .001s-12 5.372-12 12C0 18.627 5.373 24 12 24"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.328 6.552a1.2 1.2 0 0 1 1.2 1.2v2.99h2.99a1.2 1.2 0 0 1 0 2.4h-2.99v2.99a1.2 1.2 0 1 1-2.4 0v-2.99h-2.99a1.2 1.2 0 1 1 0-2.4h2.99v-2.99a1.2 1.2 0 0 1 1.2-1.2"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  );
}
